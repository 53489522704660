/*--------------------------------------------------------------------/
	aside
/--------------------------------------------------------------------*/
.aside {
	@include media-breakpoint-up(lg) {
		width: 26.66%;
	}
	&-news {
		@include radius-small;
		background: $white;
		border: 1px solid $sub_c2;
		padding:2em map-get($contmargin, sp);
		@include media-breakpoint-up(lg) {
			padding: 1.5em 2em 2em;
		}
		@include media-breakpoint-up(xl) {
			padding: 2em 3em 3em;
		}
	}
}

.widget-blog-aside {
	&:not(:last-child) {
		margin-bottom: 2.5rem;
	}
	&__tit {
		padding: 0.3rem 0 0.5rem;
		margin-bottom: 0.5rem;
		border-bottom: 1px solid $m-gray;
		@include f-w(500);
		@include f-em(18);
	}
	ul {
		li {
			@include line-h(1.4);
			&:not(:last-of-type) {
				margin-bottom: .5em;
			}
			a {
				padding-left: 1.5em;
				color: $txt_c;
				position: relative;
				@include dec-none;
				display: block;
				&::before {
					position: absolute;
					top: .3em;
					left: .2em;
					content: "●";
					vertical-align: baseline;
					@include f-em(12);
					color: $main_c;
					@include dec-none;
				}
				&:hover {
					@include dec-line;
				}
			}
		}
	}
}
.tag-cloud-link {
	font-size: 1rem !important;
	color: $txt_c;
	padding-left: 1.5em;
	margin-right: .5rem;
	position: relative;
	@include icon(tag, before);
	@include dec-none;
	&::before {
		position: absolute;
		top: 0.6em;
		left: .2rem;
		@include f-em(14);
		color: $main_c;
		@include dec-none;
	}
	&:hover {
		@include dec-line;
	}
}

/* calendar
------------------------------------*/
#wp-calendar {
	border-collapse: separate;
	width: 100%;
}
.calendar_wrap {
	padding: 0.5rem 0.75rem;
	border: 1px solid $m-gray;
}
.wp-calendar {
	&-table {
		//テーブル
		margin-bottom: 0.75rem;
		caption {
			padding: 0.3rem 2%;
		}
		& thead {
			th {
				text-align: center;
				padding: 6px;
				width: auto;
				color: $gray;
				@include f-w(500);
			}
		}
		td {
			text-align: center;
			background: $p-gray;
			line-height: 2;
			border: 2px solid $white;
			@include f-em(15);
			& a {
				background-color: $main_c;
				color: $white;
				@include transition;
				display: block;
				@include dec-none;
				&:hover {
					background-color: $sub_c1;
				}
			}
			&.pad {
				background: transparent;
			}
			&#today {
				background: $gray;
				color: $white;
			}
		}
	}
	&-nav {
		@include flex-between;
		width: 100%;
		padding-bottom: 0.3rem;
		a {
			display: block;
			color: $txt_c;
			padding: 0.4rem 1rem;
			background-color: $p-gray;
			@include dec-none;
			@include transition;
			&:hover {
				background-color: $gray;
				color: $white;
			}
		}
	}
}

/*--------------------------------------------------------------------/
	search-form
/--------------------------------------------------------------------*/
.search-form {
	position: relative;
	width: 100%;
	border: 1px solid $gray;
	display: flex;
	justify-content: space-between;
	&__txt {
		height: 40px;
		padding: 0 10px;
		border-radius: 2px;
		outline: 0;
		background: $white;
		appearance: none;
		border: none;
		width: calc(100% - 50px);
	}
	&__btn {
		height: 40px;
		width: 50px;
		padding: 2px;
		background: none;
		font-size: 2em;
		vertical-align: bottom;
		color: $m-gray;
		background: $white;
		@include transition;
		border: none;
		i {
			@include f-size(20);
		}
		&:hover {
			color: $blue;
		}
	}
}
