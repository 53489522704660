/*--------------------------------------------------------------------/
	qa
/--------------------------------------------------------------------*/
.qa-intro {
	color: $white;
	@include f-s_xs(0.94, 4);
	@include f-w(500);
	padding-top: clamp(1rem, 2vw, 2rem);
	margin-bottom: clamp(2rem, 4vw, 3rem);
	counter-reset: q-num;
	@include media-breakpoint-up(xl) {
		@include f-size(20);
	}
}

.qa-list {
	counter-reset: a-num;
}

.qa-box {
	@include radius-small;
	border: 1px solid $sub_c2;
	overflow: hidden;
	&:not(:last-of-type) {
		margin-bottom: clamp(1rem, 2vw, 2rem);
	}
	&__inner {
		background: $white;
		padding: 1.5em 5% 2em;
		@include media-breakpoint-up(md) {
			padding: 1.5em 3em 2.5em;
		}
	}
	&__tit {
		position: relative;
		padding-bottom: 1.25em;
		margin-bottom: 1.25em;
		display: flex;
		align-content: center;
		column-gap: 1.25em;
		&::before {
			position: absolute;
			left: 0;
			bottom: 0;
			background-size: 8px 1px;
			content: "";
			width: 100%;
			height: 1px;
			background-image: linear-gradient(to right, $main_c, $main_c 3px, transparent 3px, transparent 8px);
		}
		em {
			position: relative;
			flex-shrink: 0;
			font-style: normal;
			display: block;
			color: $white;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			background: $main_c;
			@include f-family(font02);
			@include line-h(1.1);
			@include flex-column;
			align-items: center;
			justify-content: center;
			@include media-breakpoint-up(md) {
				width: 80px;
				height: 80px;
			}
			&::before {
				content: "Question";
				@include l-sp(0);
				@include f-w(400);
				@include f-size(11);
				display: block;
				@include media-breakpoint-up(md) {
					@include f-size(13);
				}
			}
			&::after {
				display: block;
				counter-increment: q-num;
				@include f-w(600);
				@include l-sp(0.03em);
				content: counter(q-num, decimal-leading-zero);
				@include f-size(20);
				@include media-breakpoint-up(md) {
					@include f-size(30);
				}
			}
		}
		b {
			display: flex;
			align-items: center;
			flex-shrink: 1;
			@include f-s_xs(0.94, 4);
			@include media-breakpoint-up(md) {
				@include f-s_md(1.14, 4);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(22);
			}
		}
	}
	&__txt {
		display: flex;
		align-content: flex-start;
		column-gap: 1.25em;
		& > em {
			position: relative;
			flex-shrink: 0;
			font-style: normal;
			display: block;
			color: $white;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			background: $other_c7;
			@include f-family(font02);
			@include line-h(1.1);
			@include flex-column;
			align-items: center;
			justify-content: center;
			@include media-breakpoint-up(md) {
				width: 80px;
				height: 80px;
			}
			&::before {
				content: "Answer";
				@include l-sp(0);
				@include f-w(400);
				@include f-size(11);
				display: block;
				@include media-breakpoint-up(md) {
					@include f-size(13);
				}
			}
			&::after {
				display: block;
				counter-increment: a-num;
				@include f-w(600);
				@include l-sp(0.03em);
				content: counter(a-num, decimal-leading-zero);
				@include f-size(20);
				@include media-breakpoint-up(md) {
					@include f-size(30);
				}
			}
		}
		.txt {
			padding-top: 0.5em;
		}
	}
}
