/*--------------------------------------------------------------------/
	partner
/--------------------------------------------------------------------*/
/* partner intro */
.partner-intro {
	color: $white;
	@include f-s_xs(0.94, 4);
	@include f-w(500);
	padding-top: clamp(2rem, 3vw, 3rem);
	margin-bottom: clamp(3rem, 4vw, 4rem);
	@include media-breakpoint-up(xl) {
		@include f-size(20);
	}
}

/* partner btn */
.partner-btn {
	padding-bottom: clamp(3rem, 6vw, 6rem);
	@include media-breakpoint-up(lg) {
		margin-bottom: clamp(3rem, 6vw, 6rem);
	}
	&__list {
		position: relative;
		z-index: z-index(module, part04);
		@include media-breakpoint-up(lg) {
			@include flex-between;
			column-gap: 3.84%;
		}
		@include media-breakpoint-up(xl) {
			column-gap: 4%;
		}
		li {
			@include media-breakpoint-up(lg) {
				width: 30.77%;
				flex-shrink: 1;
			}
			@include media-breakpoint-up(xl) {
				width: 30.66%;
			}
			@include media-breakpoint-down(md) {
				&:not(:last-of-type) {
					margin-bottom: 0.75rem;
				}
			}
			a {
				@include dec-none;
				color: $txt_c;
				@include f-w(600);
				@include transition;
				padding: 1em;
				background: $white;
				border: 1px solid $l-gray;
				padding: 1rem 5%;
				width: 100%;
				height: 60px;
				border-radius: 30px;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				@include icon(down_arrow, after);
				@include media-breakpoint-up(md) {
					height: 80px;
					border-radius: 40px;
				}
				&::after {
					@include transition;
					position: absolute;
					color: $main_c;
					top: 50%;
					right: 1em;
					transform: translateY(-45%);
					@include f-em(22);
				}
				&:hover {
					background: $point_c;
					color: $white;
					border-color: $white;
					&::after {
						color: $white;
					}
				}
				span {
					@include f-s_xs(1, 6);
					@include media-breakpoint-up(lg) {
						@include f-s_lg(0.84, 3);
					}
					@include media-breakpoint-up(xl) {
						@include f-size(20);
					}
				}
			}
		}
	}
}

/* partner featurefeature */
.partner-feature {
	padding-top: clamp(3rem, 5vw, 5rem);
	&__list {
		@include media-breakpoint-up(lg) {
			padding-top: 2rem;
		}
		& > * {
			@include media-breakpoint-down(xs) {
				&:not(:last-of-type) {
					margin-bottom: clamp(2rem, 4vw, 4rem);
				}
			}
		}
	}
}

.partner-feature__box {
	&__inner {
		@include flex-c-reverse;
	}
	&__txt {
		width: 90%;
		@include m-a;
		padding-left: 10%;
		padding-bottom: 1.5rem;
		border-left: 1px solid $main_c;
	}
	&__tit {
		@include f-w(600);
		margin-bottom: 1rem;
		@include f-em(20);
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
	}
	&__img {
		margin-bottom: clamp(1.25rem, 2vw, 2rem);
		width: 96%;
		@include m-a;
		@include media-breakpoint-down(xs) {
			width: 80%;
		}
		&__inner {
			@include aspect-img(1, 1);
			border-radius: 50%;
			overflow: hidden;
			border: 1px solid $l-gray;
		}
	}
}

/* partner-shop */
.partner-shop {
	&__flex {
		display: flex;
		position: relative;
		@include media-breakpoint-up(lg) {
			justify-content: space-between;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
	}
	&__left {
		position: relative;
		@include media-breakpoint-up(lg) {
			width: 21.33%;
		}
	}
	&__right {
		@include media-breakpoint-up(lg) {
			width: 69.33%;
		}
	}
	&__area {
		@include flex-column;
		row-gap: clamp(2rem, 5vw, 5rem);
	}
}

.partner-shop__link {
	@include media-breakpoint-up(lg) {
		position: sticky;
		top: 100px;
	}
	h3 {
		@include f-w(700);
		@include f-s_xs(1, 6);
		padding-bottom: 0.5em;
		color: $sub_c1;
		@include media-breakpoint-up(lg) {
			@include f-size(20);
		}
	}
	&__list {
		width: 100%;
		.is-shoplist {
			width: 100%;
		}
	}
}

.area-box {
	&__inner {
		position: relative;
		z-index: z-index(module, part04);
	}
	&__tit {
		overflow: hidden;
		@include f-w(500);
		margin-bottom: calc(1rem + 1em);
		span {
			display: inline-block;
			padding-right: 1em;
			position: relative;
			@include f-s_xs(1.14, 6);
			@include media-breakpoint-up(lg) {
				@include f-s_lg(1.25, 4);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(24);
			}
			&::after {
				position: absolute;
				content: "";
				top: 50%;
				left: 100%;
				width: 100vw;
				height: 1px;
				background: $m-gray;
			}
		}
	}
	&__shop {
		&__list {
			@include flex-wrap;
			@include media-breakpoint-up(sm) {
				row-gap: 2rem;
				& > * {
					width: 50%;
				}
			}
			@include media-breakpoint-down(xs) {
				flex-direction: column;
				row-gap: 1rem;
			}
		}
	}
}

.shop-box {
	&__inner {
		position: relative;
		padding: 0 2em;
		&::before {
			position: absolute;
			top: 3%;
			left: 0;
			content: "";
			width: 1px;
			height: 94%;
			background: $l-gray;
		}
	}
	h4 {
		@include f-w(500);
		margin-bottom: 0.5em;
		@include f-s_xs(0.94, 2);
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
	}
	.txt {
		@include line-h(1.4);
	}
}

/* partner form */
.partner-form {
	&__intro {
		@include f-s_xs(0.94, 4);
		@include f-w(500);
		margin-bottom: clamp(2rem, 4vw, 3rem);
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
	}
}
