/*--------------------------------------------------------------------/
	product
/--------------------------------------------------------------------*/
.product-intro {
	color: $white;
	padding-top: clamp(2rem, 3vw, 3rem);
	padding-bottom: clamp(4rem, 9vw, 9rem);
	&__inner {
		display: flex;
		justify-content: center;
	}
	&__catch {
		@include f-w(600);
		.is-catch01 {
			@include f-s_xs(0.94, 14);
			@include media-breakpoint-up(xl) {
				@include f-size(30);
			}
		}
		.is-catch02 {
			@include f-s_xs(1.4, 24);
			@include media-breakpoint-up(xl) {
				@include f-size(48);
			}
		}
		.is-catch03 {
			@include f-s_xs(0.94, 14);
			@include media-breakpoint-up(xl) {
				@include f-size(30);
			}
		}
	}
}

//product-point
.product-point {
	padding-bottom: clamp(2rem, 10vw, 12rem);
	@include media-breakpoint-up(xl) {
		padding-top: clamp(5rem, 9vw, 9rem);
	}
	&__inner {
		@include media-breakpoint-down(lg) {
			padding: clamp(2rem, 4vw, 4rem) 5% clamp(2rem, 3vw, 3rem);
			@include radius-medium;
			background: $white;
		}
	}
	&__tit {
		margin-bottom: calc(1.5rem + 0.5em);
		@include f-s_xs(1.14, 16);
		@include f-w(600);
		position: relative;
		color: $sub_c1;
		@include media-breakpoint-up(xl) {
			@include f-size(36);
		}
		&::before {
			content: "";
			width: 100%;
			height: 2px;
			background: $sub_c1;
			position: absolute;
			left: 0;
			z-index: z-index(module, part04);
			top: calc(50% - 1px);
		}
		span {
			position: relative;
			display: inline-block;
			background: $white;
			padding-right: 1em;
			z-index: z-index(module, part03);
		}
	}
	&__list {
		display: flex;
		counter-reset: point-num;
		@include media-breakpoint-up(lg) {
			flex-wrap: wrap;
			column-gap: 6.66%;
			row-gap: 2rem;
			& > * {
				width: 43.33%;
				&:nth-of-type(4n - 1) {
					margin-left: 6.66%;
				}
			}
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
	}
}

.product-point__box {
	&__inner {
		position: relative;
		padding-left: 90px;
		padding-bottom: 2rem;
		@include media-breakpoint-up(md) {
			padding-left: 150px;
		}
	}
	&__txt {
		padding-top: 20px;
		@include media-breakpoint-up(md) {
			padding-top: 50px;
		}
	}
	&__tit {
		@include f-w(700);
		margin-bottom: calc(0.75rem + 0.3em);
		@include f-s_xs(1, 4);
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
	}
	&__num {
		position: absolute;
		top: 0;
		left: 0;
		&__inner {
			background: $main_c;
			border-radius: 50%;
			width: 70px;
			height: 70px;
			color: $white;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			@include media-breakpoint-up(md) {
				width: 120px;
				height: 120px;
			}
			span {
				@include f-family(font02);
				@include f-w(500);
				@include l-sp(0);
				@include line-h(1.1);
				@include f-size(12);
				@include media-breakpoint-up(md) {
					@include f-size(18);
				}
				&::after {
					counter-increment: point-num;
					content: counter(point-num, decimal-leading-zero);
					@include f-family(font03);
					@include f-w(600);
					display: block;
					@include f-size(32);
					@include media-breakpoint-up(md) {
						@include f-size(52);
					}
				}
			}
		}
	}
}

//product-patent
.product-patent {
	padding-top: clamp(2rem, 5.2vw, 5.2vw);
	padding-bottom: clamp(4rem,12vw,12rem);
	background: $o-white;
	@include lightgray_wave_top;
	position: relative;
	&__tit {
		@include m-a;
		margin-bottom: calc(1.5rem + 1em);
		img {
			@include m-a;
			display: block;
		}
	}
	&__list {
		padding-top: clamp(1rem,2vw,2rem);
		max-width: 1590px;
		@include m-a;
		@include flex-wrap;
		@include media-breakpoint-up(lg) {
			column-gap: 1.88%;
			row-gap: clamp(1rem,1.88vw,1.75rem);
		}
		@include media-breakpoint-down(md) {
			column-gap: 3%;
			row-gap: 3vw;
		}
	}
	&__img {
		width: 31.33%;
		@include media-breakpoint-up(lg) {
			width: 15.09%;
		}
		&__inner {
			@include aspect-img(12,17);
		}
	}
}

//product-sdgs
.product-sdgs {
	position: relative;
	padding: clamp(3rem, 9vw, 9rem) 0;
	background: url(../img/lv2/product_sdgs_bg.jpg) center center/cover no-repeat;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba($other_c4, 0.64);
		z-index: z-index(module, part04);
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part03);
		@include radius-medium;
		background: $white;
		padding: 2em 5%;
		@include media-breakpoint-up(md) {
			padding: 3em 8%;
		}
		@include media-breakpoint-up(lg) {
			padding: 3em 10%;
		}
	}
	&__tit {
		@include m-a;
		margin-bottom: calc(1.5rem + 1em);
		img {
			@include m-a;
			display: block;
		}
	}
	&__img {
		margin-bottom: clamp(1rem, 2vw, 2rem);
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			display: flex;
			column-gap: 4%;
			& > * {
				max-width: 48%;
			}
		}
		ul {
			li {
				span {
					display: inline-block;
					min-width: 4rem;
					@include f-w(600);
					@include media-breakpoint-up(md) {
						min-width: 6rem;
					}
				}
			}
		}
	}
}

//.product-outline
.product-outline {
	padding-top: clamp(5rem, 9vw, 9rem);
}

.product-box {
	&:not(:last-of-type) {
		margin-bottom: clamp(3rem, 6vw, 6rem);
	}
	&__flex {
		display: flex;
		padding-bottom: clamp(2rem, 3vw, 3rem);
		@include media-breakpoint-up(md) {
			justify-content: space-between;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: 2rem;
		}
	}
	&__table {
		border-top: 1px solid $m-gray;
		@include media-breakpoint-up(md) {
			width: 50%;
		}
		table {
			width: 100%;
			caption {
				text-align: left;
				@include f-em(18);
				padding: 1rem 0;
				@include f-w(700);
				border-bottom: 1px solid $m-gray;
			}
			& tr {
				border-bottom: 1px solid $m-gray;
			}
			& th,
			& td.column-1 {
				text-align: left;
				font-weight: normal;
				padding: 1rem 0;
				width: 25%;
				min-width: 120px;
				@include f-w(700);
			}
			& td {
				padding: 1rem 0 1rem 1rem;
			}
		}
	}
	&__img {
		@include media-breakpoint-up(md) {
			width: 36.92%;
		}
		&__inner {
			img {
				@include radius-small;
				border: 1px solid $m-gray;
				overflow: hidden;
			}
		}
	}
	&__list {
		padding-bottom: clamp(2rem, 3vw, 3rem);
		&__img {
			margin-bottom: 2rem;
			&__inner {
				@include aspect-img;
				img {
					overflow: hidden;
					@include radius-small;
					border: 1px solid $m-gray;
				}
			}
			figcaption {
				margin-top: 1rem;
			}
		}
	}
}
