@mixin btn_base {
	font-weight: normal;
	display: block;
	text-align: center;
	position: relative;
	line-height: 1.4;
	@include transition;
	@include dec-none;
}

@mixin btn_normal($color: $white, $bg: $main_c, $border: $main_c, $color_h: $white, $bg_h: $sub_c, $border_h: $sub_c) {
	@include btn_base;
	color: $color;
	background-color: $bg;
	border: 1px solid $border;
	padding: 1.5rem 4%;
	width: 100%;
	border-radius: 36px;
	&:hover {
		color: $color_h;
		background-color: $bg_h;
		border-color: $border_h;
	}
}

//矢印あり
@mixin btn_arrow(
	$color: $white,
	$bg: $main_c,
	$border: $main_c,
	$icon: $white,
	$color_h: $white,
	$bg_h: $point_c,
	$border_h: $point_c,
	$icon_h: $white
) {
	@include btn_base;
	color: $color;
	background-color: $bg;
	border: 1px solid $border;
	padding: 1.5rem 4%;
	width: 100%;
	border-radius: 36px;
	@include icon(right_arrow, after);
	&::after {
		position: absolute;
		right: 1rem;
		color: $icon;
		@include f-em(24);
		@include transition;
		top: 50%;
		transform: translateY(-50%);
	}
	&:hover {
		color: $color_h;
		background-color: $bg_h;
		border-color: $border_h;
		&::after {
			color: $icon_h;
		}
	}
}

//丸ボタン
@mixin btn_circle(
	$color: $white,
	$bg: $main_c,
	$border: $main_c,
	$color_h: $white,
	$bg_h: $sub_c1,
	$border_h: $sub_c1
) {
	font-weight: normal;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	line-height: 1.4;
	@include transition;
	@include dec-none;
	width: 120px;
	height: 120px;
	color: $color;
	background: $bg;
	border: 1px solid $border;
	border-radius: 50%;
	@include media-breakpoint-up(sm) {
		width: 170px;
		height: 170px;
	}
	span {
		@include media-breakpoint-down(xs) {
			@include f-em(12);
		}
	}
	&::after {
		position: absolute;
		top: 50%;
		@include transition;
		left: calc(100% - 12px);
		content: "";
		width: 24px;
		height: 1px;
		background-color: $border;
		@include media-breakpoint-up(sm) {
			width: 40px;
			left: calc(100% - 20px);
		}
	}
	&:hover {
		color: $color_h;
		background-color: $bg_h;
		border-color: $border_h;
		&::after {
			background-color: $border_h;
			width: 32px;
			@include media-breakpoint-up(sm) {
				width: 50px;
			}
		}
	}
}
