/*--------------------------------------------------------------------/
	documents
/--------------------------------------------------------------------*/

.documents-intro {
	color: $white;
	@include f-s_xs(0.94, 4);
	@include f-w(500);
	padding-top: clamp(1rem, 2vw, 2rem);
	margin-bottom: clamp(2rem, 4vw, 3rem);
	@include media-breakpoint-up(xl) {
		@include f-size(20);
	}
}

.documents-btn {
	&__inner {
		@include radius-medium;
		background: $white;
		border: 1px solid $main_c;
		padding: clamp(1.5em, 2.5vw, 2.5rem) 5% clamp(2em, 4vw, 4rem);
	}
	&__tit {
		font-family: #{map-get($font-set, font02)}, #{map-get($font-set, font01)};
		text-align: center;
		color: $main_c;
		@include f-w(700);
		@include line-h(1.4);
		@include l-sp(0.05em);
		@include f-s_xs(1.14, 12);
		margin-bottom: clamp(1.5rem, 2vw, 2rem);
		@include media-breakpoint-up(xl) {
			@include f-size(32);
		}
	}
	&__list {
		@include flex-column;
		row-gap: clamp(0.5rem, 1vw, 1rem);
	}
	&__btn {
		@include line-h(1.4);
		a {
			border: 1px solid $main_c;
			background: $white;
			@include radius(36px);
			@include dec-none;
			@include f-w(700);
			@include transition;
			padding: 1em 2em 1em 1.5em;
			display: block;
			color: $txt_c;
			position: relative;
			width: 100%;
			@include icon(down_arrow, after);
			@include f-s_xs(.9,2);
			@include media-breakpoint-up(xl) {
				@include f-size(18);
			}
			&::after {
				position: absolute;
				right: 0.8em;
				top: 50%;
				transform: translateY(-50%);
				font-size: 1.4em;
				color: $main_c;
				@include transition;
			}
			&:hover {
				background: $main_c;
				color: $white;
				&::after {
					color: $white;
					top:calc(50% + .2em);
				}
				span {
					&::before {
						color: $white;
					}
				}
			}
			span {
				padding-left: 1.5em;
				display: block;
				position: relative;
				&::before {
					content: "●";
					position: absolute;
					top: 0.2em;
					left: 0;
					color: $main_c;
					font-size: 0.8em;
				}
			}
		}
	}
}

.documents-list {
	padding-top: clamp(6rem, 9vw, 9rem);
	display: flex;
	flex-direction: column;
	row-gap: clamp(3rem, 6vw, 6rem);
}

.documents-box {
	&__tit {
		padding: 1em 1.25em;
		border: 1px solid $main_c;
		background: $white;
		border-radius: clamp(6px, 0.5vw, 10px);
		margin-bottom: clamp(1.5rem, 2.5vw, 2.5rem);
		@include f-s_xs(1,6);
		@include media-breakpoint-up(xl) {
			@include f-size(22);
		}
		span {
			padding-left: 1.5em;
			display: block;
			position: relative;
			&::before {
				content: "●";
				position: absolute;
				top: 0.2em;
				left: 0;
				color: $main_c;
				font-size: 0.8em;
			}
		}
	}
	&__txt {
		padding: 0 2% clamp(1.5em, 2vw, 2em);
		.txt {
			margin-bottom: 1.5em;
		}
	}
	&__list {
		@include flex-column;
		row-gap: clamp(0.5em, 1vw, 1rem);
	}
	&__btn {
		a {
			padding: 1.25em 1.5em 1.25em 2em;
			background: $main_c;
			@include dec-none;
			display: block;
			@include transition;
			color: $white;
			@include radius(999px);
			@include f-w(500);
			@include line-h(1.4);
			@include f-s_xs(0.85,5);
			@include media-breakpoint-up(xl) {
				@include f-size(20);
			}
			span {
				display: block;
				position: relative;
				@include icon(document);
				padding-left: 1.75em;
				&::before {
					position: absolute;
					top: .1em;
					left: 0;
					color: $white;
					font-size: 1.2em;
				}
			}
			&:hover {
				background: $point_c;
			}
		}
	}
}
