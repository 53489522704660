/*--------------------------------------------------------------------/
	contact_form
/--------------------------------------------------------------------*/

.contact-intro {
	color: $white;
	@include f-s_xs(0.94, 4);
	@include f-w(500);
	padding-top: clamp(1rem, 2vw, 2rem);
	margin-bottom: clamp(2rem, 4vw, 3rem);
	@include media-breakpoint-up(xl) {
		@include f-size(20);
	}
}

.contact-list {
	margin-bottom: clamp(3rem, 5vw, 5rem);
	border: 2px solid $other_c3;
	background: $white;
	@include radius-medium;
	padding: 1.5rem map-get($contmargin, sp) 2rem;
	@include media-breakpoint-up(md) {
		padding: 3rem 8% 3.5rem;
	}
}

.contact-privacy {
	border: 1px solid $gray;
	@include radius-small;
	@include media-breakpoint-up(lg) {
		padding: 1.5rem map-get($contmargin, sp);
	}
	&__inner {
		@include media-breakpoint-up(lg) {
			overflow-y: scroll;
			max-height: 300px;
			@include scroll-bar;
		}
	}
	&__tit {
		@include f-s_xs(1, 4);
		@include f-w(500);
		text-align: center;
		@include media-breakpoint-up(lg) {
			padding: 3rem 10%;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
		@include media-breakpoint-down(md) {
			padding: 1.5rem 6%;
			cursor: pointer;
			position: relative;
			@include icon(down_arrow, after);
			&::after {
				position: absolute;
				right: 1rem;
				top: calc(50% - 10px);
				@include transition;
				@include f-size(24);
			}
		}
		&.active {
			&::after {
				transform: rotate(180deg);
			}
		}
	}
	&__txt {
		@include media-breakpoint-down(md) {
			border-top: 1px solid $gray;
		}
	}
	.txt {
		@include media-breakpoint-up(md) {
			@include f-em(15);
		}
		@include media-breakpoint-up(lg) {
			padding: 0 10% 3rem;
		}
		@include media-breakpoint-down(md) {
			padding: 1rem 6% 3rem;
		}
	}
}

/*-------------------------------------------------
	contact form
-------------------------------------------------*/
/* variablea */
$radio_height: 20px; //チェックボックスの高さ
$duration_radio: 0.4s; //チェックボックスのアニメーションのスピード
$checkbox_height: 20px; //チェックボックスの高さ
$duration_checkbox: 0.4s; //チェックボックスのアニメーションのスピード

.contact-form {
	&__tit {
		color: $sub_c1;
		text-align: center;
		@include f-family(font02);
		@include f-w(600);
		@include l-sp(0);
		@include f-s_xs(1.75, 20);
		margin-bottom: calc(1.5rem + 0.3em);
		@include media-breakpoint-up(xl) {
			@include f-size(48);
		}
	}
	.btn-wrap {
		@include m-a;
		width: 100%;
		@include media-breakpoint-up(sm) {
			max-width: 320px;
		}
	}
}
.contact-table {
	width: 100%;
	@include m-a;
	line-height: 1.2;
	position: relative;
	img {
		width: 100%;
	}
	& th,
	& td {
		text-align: left;
		@include media-breakpoint-up(lg) {
			display: table-cell;
			padding: 1rem 0 2rem;
		}
		@include media-breakpoint-down(md) {
			display: block;
		}
	}
	& th {
		vertical-align: top;
		@include f-w(600);
		@include media-breakpoint-up(lg) {
			width: unquote("max(30%,280px)");
		}
		& .required {
			padding: 0.2em 0.4em;
			@include line-h(1);
			margin-left: 0.3em;
			font-size: 0.9em;
		}
	}
	& td {
		@include media-breakpoint-up(lg) {
			width: 64%;
			padding-left: 4%;
		}
		@include media-breakpoint-down(md) {
			padding: 1.5rem 0 2.5rem;
			padding-top: 1.5rem;
		}
	}
	& textarea {
		width: 100%;
	}
}

input,
select {
	width: auto;
	&.long {
		width: 100%;
	}
	&.middle {
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 70%;
		}
	}
	&.short {
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 30%;
		}
	}
	&.mini {
		width: 10em;
	}
}

button[class*="sub-btn"],
button[class*="back-btn"],
button[class*="chack-btn"] {
	@include f-size(16);
	@include l-sp(0.1em);
}
button[class*="sub-btn"] {
	@include btn_normal($white, $main_c, $main_c, $white, $point_c, $point_c);
}
button[class*="back-btn"] {
	margin-top: 1rem;
	@include btn_normal($d-gray, $l-gray, $l-gray, $white, $black, $black);
}
button[class*="chack-btn"] {
	@include btn_normal($white, $main_c, $main_c, $white, $point_c, $point_c);
}

[type="button"],
[type="submit"] {
	-webkit-appearance: none;
}
// select
.select-wrap {
	position: relative;
	@include icon(down_arrow, after);
	&::after {
		position: absolute;
		right: 0.1em;
		top: 50%;
		transform: translateY(-50%);
	}
	&.short {
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 30%;
		}
	}
}

/* radio-btn */
.mwform-radio-field {
	&:not(:last-child) {
		margin-right: 1em;
	}
}
.mwform-radio-field-text {
	vertical-align: top;
}
input[type="radio"] {
	position: absolute;
	opacity: 0;
	+ .mwform-radio-field-text {
		&:before {
			content: "";
			background: $o-white;
			border-radius: 100%;
			border: 1px solid $gray;
			display: inline-block;
			width: $radio_height;
			height: $radio_height;
			position: relative;
			top: 0;
			margin-right: 1em;
			vertical-align: top;
			cursor: pointer;
			text-align: center;
			transition: all $duration_radio ease;
		}
	}
	&:checked {
		+ .mwform-radio-field-text {
			&:before {
				background-color: $main_c;
				box-shadow: inset 0 0 0 5px $p-gray;
			}
		}
	}
	&:focus {
		+ .mwform-radio-field-text {
			&:before {
				outline: none;
				border-color: $main_c;
			}
		}
	}
	&:disabled {
		+ .mwform-radio-field-text {
			&:before {
				box-shadow: inset 0 0 0 4px $gray;
				border-color: $gray;
				background: $o-white;
			}
		}
	}
	+ .mwform-radio-field-text {
		&:empty {
			&:before {
				margin-right: 0;
			}
		}
	}
}
.vertical-item:not(:first-of-type) {
	margin-top: 1rem !important;
}

/* chack-btn */
@-moz-keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: $checkbox_height/2;
	}
}

@-webkit-keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: $checkbox_height/2;
	}
}

@keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: $checkbox_height/2;
	}
}

@keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}
@-webkit-keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}
@-moz-keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}

input[type="checkbox"] {
	display: none;
}

.check-box {
	& .vertical-item:not(:first-of-type) {
		margin-top: 1rem !important;
	}
	& label {
		position: relative;
		cursor: pointer;
		&::before {
			content: "";
			height: $checkbox_height;
			width: $checkbox_height;
			border-radius: 3px;
			background-color: transparent;
			border: 1px solid $gray;
			margin-right: 1em;
			position: absolute;
			top: -0.05em;
			left: 0;
			display: block;
			transition: border-color ease $duration_checkbox/2;
			z-index: z-index(module, part04);
		}
	}
}

.mwform-checkbox-field-text {
	vertical-align: top;
	padding-left: calc(#{$checkbox_height} + 1rem);
	display: block;
	&::before,
	&::after {
		position: absolute;
		height: 0;
		width: $checkbox_height * 0.2;
		background-color: $main_c;
		display: inline-block;
		transform-origin: left top;
		content: "";
		transition: opacity ease 0.5;
		z-index: z-index(module, part03);
	}
	&::before {
		top: $checkbox_height * 0.86;
		left: $checkbox_height * 0.4;
		transform: rotate(-135deg);
	}
	&::after {
		top: $checkbox_height * 0.5;
		left: $checkbox_height * 0.03;
		transform: rotate(-45deg);
	}
}

input[type="checkbox"]:checked + .mwform-checkbox-field-text,
.mwform-checkbox-field-text.checked {
	border-color: $main_c;
	&::after {
		height: $checkbox_height * 0.5;
		animation: dothabottomcheck $duration_checkbox/2 ease 0s forwards;
	}

	&::before {
		height: $checkbox_height * 1.2;
		animation: dothatopcheck $duration_checkbox ease 0s forwards;
		box-shadow: 0 0 0 $checkbox_height * 0.05 $white;
	}
}

.mw_wp_form .error {
	margin-top: 0.7rem;
}

/* form-parts
----------------------------------------------------------------*/

%form-parts {
	background-color: $white;
	padding: 1em;
	border: 1px solid $gray;
	line-height: 1.5;
	border-radius: 4px;
	&:focus {
		background-color: #fff;
		box-shadow: rgba(58, 134, 232, 0.25) 0 0 4px 1px;
	}
}

%placeholder {
	color: $gray;
}

input {
	@extend %form-parts;
	&::-webkit-input-placeholder {
		@extend %placeholder;
	}
	&:-moz-placeholder {
		@extend %placeholder;
	}
	&:-ms-input-placeholder {
		@extend %placeholder;
	}
}
textarea {
	@extend %form-parts;
	height: 10em;
}
select {
	@extend %form-parts;
}
