@charset "utf-8";
@import "base";
/*====================================================================

lv2.css

=====================================================================*/
/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
	&__lv2 {
		position: relative;
		overflow: hidden;
		@include media-breakpoint-up(md) {
			height: 55.76vw;
			padding-bottom: 16.875vw;
		}
		@include media-breakpoint-only(lg) {
			height: 580px;
		}
		@include media-breakpoint-up(xl) {
			height: 740px;
			padding-bottom: 324px;
		}
		@include media-breakpoint-up(xxl) {
			padding-bottom: 16.875vw;
		}
		@include media-breakpoint-down(sm) {
			height: 83.33vw;
		}
		@include media-breakpoint-down(xxs) {
			height: 400px;
		}
	}
	&__single {
		position: relative;
		overflow: hidden;
		@include media-breakpoint-up(md) {
			height: 55.76vw;
		}
		@include media-breakpoint-only(lg) {
			height: 580px;
		}
		@include media-breakpoint-up(xl) {
			height: 740px;
		}
		@include media-breakpoint-down(sm) {
			height: 400px;
		}
		@include media-breakpoint-down(xxs) {
			height: 400px;
		}
	}
}
.main-view {
	&__bottom {
		position: absolute;
		left: 0;
		bottom: -2px;
		width: 100%;
		height: 16.875vw;
		z-index: z-index(module, part04);
		@include media-breakpoint-up(xl) {
			height: 324px;
		}
		@include media-breakpoint-up(xxl) {
			height: 16.875vw;
		}
		&__inner {
			width: 100%;
			height: 100%;
			background: url(../img/lv2/lv2_main_bottom.svg) center bottom/cover no-repeat;
		}
	}
	&__add {
		position: absolute;
		bottom: 3em;
		left: 50%;
		min-width: 380px;
		width: 80%;
		z-index: z-index(module, part03);
		@include media-breakpoint-up(xl) {
			left: calc(50% - 6rem);
			bottom: 0;
			width: 1163px;
		}
		@include media-breakpoint-up(xxl) {
			width: 60.57%;
		}
		&__inner {
			width: 100%;
		}
	}
}
.main-view__lv2 {
	& .l-cont {
		position: relative;
		height: 100%;
		z-index: z-index(module, part02);
	}
	&__tit {
		position: absolute;
		top: 65%;
		transform: translateY(-50%);
		@include f-w(600);
		&__inner {
			em {
				font-style: normal;
				@include f-family(font02);
				@include f-w(600);
				line-height: 1.4;
				@include f-s_xs(2.5, 48);
				@include l-sp(0);
				display: block;
				@include media-breakpoint-up(xl) {
					@include f-size(90);
				}
				&.is-404 {
					padding-top: clamp(1rem, 3vw, 3rem);
					@include f-s_xs(2, 32);
					@include media-breakpoint-up(xl) {
						@include f-size(72);
					}
				}
			}
			span {
				@include f-family(font01);
				@include l-sp(0.05);
				font-style: normal;
				display: inline-block;
				@include f-w(700);
				@include f-s_xs(0.85, 20);
				padding-left: 0.2em;
				position: relative;
				line-height: 1.2;
				@include media-breakpoint-up(xl) {
					@include f-size(32);
				}
			}
		}
	}
	&__term {
		display: block;
		padding-left: 0.2em;
		margin-top: clamp(1rem,2vw,2rem);
		@include f-s_xs(0.85, 12);
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
		b {
			color: $main_c;
		}
	}
}

/* single page */
.main-view__single {
	& .l-cont {
		position: relative;
		height: 100%;
		z-index: z-index(module, part02);
	}
	&__tit {
		position: absolute;
		top: 60%;
		transform: translateY(-50%);
		width: 100%;
		@include media-breakpoint-up(md) {
			top: 50%;
		}
		&__inner {
			position: relative;
			width: 90%;
			padding-bottom: 2rem;
			&::after {
				content: "";
				background: $main_c;
				width: 100vw;
				height: 4px;
				position: absolute;
				bottom: 0;
				right: calc(100% - 10rem);
				@include media-breakpoint-up(xl) {
					right: calc(100% - 20rem);
				}
			}
			h1 {
				@include f-w(600);
				@include f-family(font01);
				@include l-sp(0.05);
				font-style: normal;
				display: inline-block;
				@include f-w(700);
				@include f-s_xs(1.14, 24);
				padding-left: 0.2em;
				position: relative;
				line-height: 1.2;
				margin-bottom: clamp(0.75rem,2vw,2rem);
				@include media-breakpoint-up(xl) {
					@include f-size(44);
				}
			}
		}
	}
	&__flex {
		display: flex;
		column-gap: .5em;
	}
	&__date {
		@include f-family(font02);
		color: $main_c;
		@include f-w(600);
		@include f-s_xs(0.94,8);
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
	&__category {
		display: flex;
		flex-wrap: wrap;
		column-gap: .5em;
		row-gap: 0.3em;
		& .category-item {
			@include f-em(13);
			padding: 0.25em 0.5em;
			min-width: 130px;
			vertical-align: top;
			@include media-breakpoint-up(md) {
				min-width: 180px;
			}
			@include media-breakpoint-up(lg) {
				@include f-em(16);
				padding: 0.5em 1em;
			}
		}
	}
}

/*--------------------------------------------------------------------/
/* contents
/--------------------------------------------------------------------*/
.contents-bg__blue {
	content: "";
	position: absolute;
	width: 100%;
	height: 40vw;
	min-height: 365px;
	background: $other_c3;
	z-index: -1;
	@include media-breakpoint-up(lg) {
		height: 465px;
	}
	&__inner {
		content: "";
		width: 100%;
		height: 100%;
		padding-bottom: 5.2vw;
		position: relative;
		@include white_wave_bottom;
	}
}

/* 404 */
.box-404 {
	border: 2px solid$other_c3;
	background: $white;
	padding: 1.5rem map-get($contmargin, sp) 2rem;
	@include radius-medium;
	@include media-breakpoint-up(md) {
		padding: 3rem 8% 3.5rem;
	}
}



@import "_components/breadcrumb"; //パンくずリスト
@import "_plugins/lightbox"; //lightbox
@import "_lv2/concept"; //ドットコンとは
@import "_lv2/product"; //製品概要
@import "_lv2/method"; //施工方法
@import "_lv2/cases"; //施工事例
@import "_lv2/qa"; //よくある質問
@import "_lv2/news"; //新着情報
@import "_lv2/partner"; //パートナー施工店
@import "_lv2/documents"; //資料ダウンロード
@import "_lv2/contact"; //お問い合わせ
@import "_lv2/aside"; //サイドバー
