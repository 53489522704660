@charset "UTF-8";
/* webfont 変数　*/
/* ofi */
@media (min-width: 1920px) {
  /*====================================================================/
  /*--Large PC 以上--
  /====================================================================*/
}
@media (min-width: 1557px) {
  /*====================================================================/
  /*---PC 以上--
  /====================================================================*/
}
@media (min-width: 1057px) {
  /*====================================================================/
  /*--XGAサイズ　iPadPro 以上--
  /====================================================================*/
}
@media (min-width: 768px) {
  /*====================================================================/
  /*--タブレット　iPadmini 以上--
  /====================================================================*/
}
@media (min-width: 576px) {
  /*====================================================================/
  /*--スマホ 以上--
  /====================================================================*/
}
@media (min-width: 376px) {
  /*====================================================================/
  /*--iPhone 以上--
  /====================================================================*/
}
@media (max-width: 1919px) {
  /*====================================================================/
  /*--Large PC 未満--
  /====================================================================*/
}
@media (max-width: 1556px) {
  /*====================================================================/
  /*--PC 未満--
  /====================================================================*/
}
@media (max-width: 1056px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro 未満--
  /====================================================================*/
}
@media (max-width: 767px) {
  /*====================================================================/
  /*--タブレット iPadmini 未満--
  /====================================================================*/
}
@media (max-width: 575px) {
  /*====================================================================/
  /*--iPhone 以下--
  /====================================================================*/
}
@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
  /*--スマホ以上 タブレット iPadmini未満--
  /====================================================================*/
}
@media (min-width: 768px) and (max-width: 1056px) {
  /*====================================================================/
  /*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
  /====================================================================*/
}
@media (min-width: 1057px) and (max-width: 1556px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro以上 PC未満--
  /====================================================================*/
}
@media (min-width: 1557px) and (max-width: 1919px) {
  /*====================================================================/
  /*--px以上 Large PC未満--
  /====================================================================*/
}
/*====================================================================

lv2.css

=====================================================================*/
/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view__lv2 {
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .main-view__lv2 {
    height: 55.76vw;
    padding-bottom: 16.875vw;
  }
}
@media (min-width: 1057px) and (max-width: 1556px) {
  .main-view__lv2 {
    height: 580px;
  }
}
@media (min-width: 1557px) {
  .main-view__lv2 {
    height: 740px;
    padding-bottom: 324px;
  }
}
@media (min-width: 1920px) {
  .main-view__lv2 {
    padding-bottom: 16.875vw;
  }
}
@media (max-width: 767px) {
  .main-view__lv2 {
    height: 83.33vw;
  }
}
@media (max-width: 375px) {
  .main-view__lv2 {
    height: 400px;
  }
}
.main-view__single {
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .main-view__single {
    height: 55.76vw;
  }
}
@media (min-width: 1057px) and (max-width: 1556px) {
  .main-view__single {
    height: 580px;
  }
}
@media (min-width: 1557px) {
  .main-view__single {
    height: 740px;
  }
}
@media (max-width: 767px) {
  .main-view__single {
    height: 400px;
  }
}
@media (max-width: 375px) {
  .main-view__single {
    height: 400px;
  }
}

.main-view__bottom {
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 16.875vw;
  z-index: 1;
}
@media (min-width: 1557px) {
  .main-view__bottom {
    height: 324px;
  }
}
@media (min-width: 1920px) {
  .main-view__bottom {
    height: 16.875vw;
  }
}
.main-view__bottom__inner {
  width: 100%;
  height: 100%;
  background: url(../img/lv2/lv2_main_bottom.svg) center bottom/cover no-repeat;
}
.main-view__add {
  position: absolute;
  bottom: 3em;
  left: 50%;
  min-width: 380px;
  width: 80%;
  z-index: 2;
}
@media (min-width: 1557px) {
  .main-view__add {
    left: calc(50% - 6rem);
    bottom: 0;
    width: 1163px;
  }
}
@media (min-width: 1920px) {
  .main-view__add {
    width: 60.57%;
  }
}
.main-view__add__inner {
  width: 100%;
}

.main-view__lv2 .l-cont {
  position: relative;
  height: 100%;
  z-index: 3;
}
.main-view__lv2__tit {
  position: absolute;
  top: 65%;
  transform: translateY(-50%);
  font-weight: 600;
}
.main-view__lv2__tit__inner em {
  font-style: normal;
  font-family: 'Gantari', sans-serif;
  font-weight: 600;
  line-height: 1.4;
  font-size: calc(2.5rem + 48 * (100vw - 280px) / 1220);
  letter-spacing: 0;
  display: block;
}
@media (min-width: 1557px) {
  .main-view__lv2__tit__inner em {
    font-size: 5.63rem;
  }
}
.main-view__lv2__tit__inner em.is-404 {
  padding-top: clamp(1rem, 3vw, 3rem);
  font-size: calc(2rem + 32 * (100vw - 280px) / 1220);
}
@media (min-width: 1557px) {
  .main-view__lv2__tit__inner em.is-404 {
    font-size: 4.5rem;
  }
}
.main-view__lv2__tit__inner span {
  font-family: 'Noto Sans JP', sans-serif;
  letter-spacing: 0.05;
  font-style: normal;
  display: inline-block;
  font-weight: 700;
  font-size: calc(0.85rem + 20 * (100vw - 280px) / 1220);
  padding-left: 0.2em;
  position: relative;
  line-height: 1.2;
}
@media (min-width: 1557px) {
  .main-view__lv2__tit__inner span {
    font-size: 2rem;
  }
}
.main-view__lv2__term {
  display: block;
  padding-left: 0.2em;
  margin-top: clamp(1rem, 2vw, 2rem);
  font-size: calc(0.85rem + 12 * (100vw - 280px) / 1220);
}
@media (min-width: 1557px) {
  .main-view__lv2__term {
    font-size: 1.5rem;
  }
}
.main-view__lv2__term b {
  color: #2CABED;
}

/* single page */
.main-view__single .l-cont {
  position: relative;
  height: 100%;
  z-index: 3;
}
.main-view__single__tit {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  width: 100%;
}
@media (min-width: 768px) {
  .main-view__single__tit {
    top: 50%;
  }
}
.main-view__single__tit__inner {
  position: relative;
  width: 90%;
  padding-bottom: 2rem;
}
.main-view__single__tit__inner::after {
  content: "";
  background: #2CABED;
  width: 100vw;
  height: 4px;
  position: absolute;
  bottom: 0;
  right: calc(100% - 10rem);
}
@media (min-width: 1557px) {
  .main-view__single__tit__inner::after {
    right: calc(100% - 20rem);
  }
}
.main-view__single__tit__inner h1 {
  font-weight: 600;
  font-family: 'Noto Sans JP', sans-serif;
  letter-spacing: 0.05;
  font-style: normal;
  display: inline-block;
  font-weight: 700;
  font-size: calc(1.14rem + 24 * (100vw - 280px) / 1220);
  padding-left: 0.2em;
  position: relative;
  line-height: 1.2;
  margin-bottom: clamp(0.75rem, 2vw, 2rem);
}
@media (min-width: 1557px) {
  .main-view__single__tit__inner h1 {
    font-size: 2.75rem;
  }
}
.main-view__single__flex {
  display: flex;
  column-gap: 0.5em;
}
.main-view__single__date {
  font-family: 'Gantari', sans-serif;
  color: #2CABED;
  font-weight: 600;
  font-size: calc(0.94rem + 8 * (100vw - 280px) / 1220);
}
@media (min-width: 1557px) {
  .main-view__single__date {
    font-size: 1.5rem;
  }
}
.main-view__single__category {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5em;
  row-gap: 0.3em;
}
.main-view__single__category .category-item {
  font-size: 0.76em;
  padding: 0.25em 0.5em;
  min-width: 130px;
  vertical-align: top;
}
@media (max-width: 1056px) {
  .main-view__single__category .category-item {
    font-size: 0.86em;
  }
}
@media (max-width: 767px) {
  .main-view__single__category .category-item {
    font-size: 0.92em;
  }
}
@media (min-width: 768px) {
  .main-view__single__category .category-item {
    min-width: 180px;
  }
}
@media (min-width: 1057px) {
  .main-view__single__category .category-item {
    font-size: 0.94em;
    padding: 0.5em 1em;
  }
}
@media (min-width: 1057px) and (max-width: 1056px) {
  .main-view__single__category .category-item {
    font-size: 1.06em;
  }
}
@media (min-width: 1057px) and (max-width: 767px) {
  .main-view__single__category .category-item {
    font-size: 1.14em;
  }
}

/*--------------------------------------------------------------------/
/* contents
/--------------------------------------------------------------------*/
.contents-bg__blue {
  content: "";
  position: absolute;
  width: 100%;
  height: 40vw;
  min-height: 365px;
  background: #0087CA;
  z-index: -1;
}
@media (min-width: 1057px) {
  .contents-bg__blue {
    height: 465px;
  }
}
.contents-bg__blue__inner {
  content: "";
  width: 100%;
  height: 100%;
  padding-bottom: 5.2vw;
  position: relative;
}
.contents-bg__blue__inner::after {
  content: "";
  width: 100vw;
  height: 5.2vw;
  background: url(../img/common/white_wave.svg) left bottom/cover no-repeat;
  position: absolute;
  left: 0;
  bottom: -2px;
}

/* 404 */
.box-404 {
  border: 2px solid #0087CA;
  background: #fff;
  padding: 1.5rem 5% 2rem;
  border-radius: clamp(16px, 2vw, 2em);
}
@media (min-width: 768px) {
  .box-404 {
    padding: 3rem 8% 3.5rem;
  }
}

/*--------------------------------------------------------------------/
	breadcrumb
/--------------------------------------------------------------------*/
.breadcrumb {
  position: absolute;
  left: 5%;
  top: 5rem;
  padding-right: 5%;
}
@media (min-width: 1057px) {
  .breadcrumb {
    left: 20px;
    top: 6rem;
    padding-right: 20px;
  }
}
.breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9em;
}
@media (min-width: 1057px) {
  .breadcrumb__list {
    padding-left: 0.5em;
    font-size: 0.8em;
  }
}
.breadcrumb__list .item-home {
  font-size: 1.2em;
}
.breadcrumb__list li {
  position: relative;
  color: #6F757B;
}
.breadcrumb__list li:not(:last-child) {
  padding-right: 1.5rem;
}
.breadcrumb__list li:not(:last-child):after {
  content: "\e315";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.breadcrumb__list li:not(:last-child)::after {
  position: absolute;
  right: 0.3rem;
  top: 50%;
  transform: translateY(-50%);
}
.breadcrumb__list a {
  color: #979DA4;
  transition: all 0.3s ease 0s;
  vertical-align: top;
}
.breadcrumb__list a:first-child {
  text-decoration: none !important;
}
.breadcrumb__list a:hover {
  color: #2CABED;
}

/* Preload images */
body:after {
  content: url(../img/common/close.png) url(../img/common/loading.gif) url(../img/common/prev.png) url(../img/common/next.png);
  display: none;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  /* Image border */
  border: 4px solid white;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  /* Background color behind image.
     This is visible during transitions. */
  background-color: white;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../img/common/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../img/common/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../img/common/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
  padding-top: 0.5rem;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../img/common/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/*--------------------------------------------------------------------/
	concept
/--------------------------------------------------------------------*/
/* concept-intro */
.concept-intro {
  position: relative;
  background: #0098E0;
  color: #fff;
  z-index: 1;
  padding-bottom: clamp(6rem, 10vw, 10rem);
}
.concept-intro__wrap {
  padding-top: clamp(4rem, 10vw, 10rem);
}
.concept-intro::after {
  content: "";
  width: 100vw;
  height: 5.2vw;
  background: url(../img/common/lightblue_wave.svg) left bottom/cover no-repeat;
  position: absolute;
  left: 0;
  bottom: calc(100% - 2px);
}
@media (min-width: 1057px) {
  .concept-intro {
    min-height: 1020px;
  }
}
@media (max-width: 1056px) {
  .concept-intro {
    padding-top: 72vw;
  }
}
.concept-intro__inner {
  position: relative;
  z-index: 4;
}
@media (min-width: 1057px) {
  .concept-intro__inner {
    padding-top: clamp(3rem, 6vw, 6rem);
    width: 620px;
  }
}

.concept-intro__tit {
  display: flex;
  justify-content: center;
  margin-bottom: clamp(1.5rem, 3vw, 2.5rem);
}
@media (min-width: 1057px) {
  .concept-intro__tit {
    margin-bottom: 2.5rem;
  }
}
.concept-intro__tit span {
  display: block;
  line-height: 1.4;
  font-weight: 500;
  font-size: calc(1rem + 27 * (100vw - 280px) / 1220);
}
.concept-intro__tit span:nth-child(1) {
  letter-spacing: 0.15em;
}
@media (min-width: 1057px) {
  .concept-intro__tit span:nth-child(1) {
    font-size: 2.38rem;
  }
}
.concept-intro__tit span:nth-child(2) {
  letter-spacing: 0.15em;
  font-size: calc(1.34rem + 40 * (100vw - 280px) / 1220);
}
@media (min-width: 1057px) {
  .concept-intro__tit span:nth-child(2) {
    font-size: 3.25rem;
  }
}
.concept-intro__txt .txt p {
  font-weight: 500;
  line-height: 2;
  margin-bottom: 1.5rem;
}
.concept-intro__txt .txt p:last-child {
  margin-bottom: 0;
}

.concept-intro__add01 {
  position: absolute;
  z-index: 2;
}
@media (min-width: 1057px) {
  .concept-intro__add01 {
    max-width: 740px;
    width: 740px;
    top: 0;
    left: calc(50% + 208px);
  }
}
@media (max-width: 1056px) {
  .concept-intro__add01 {
    width: 72%;
    top: -9vw;
    left: -5%;
  }
}
.concept-intro__add01__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  border-radius: 50%;
  overflow: hidden;
}
.concept-intro__add01__inner::before {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
}
.concept-intro__add01__inner > div,
.concept-intro__add01__inner figure,
.concept-intro__add01__inner a,
.concept-intro__add01__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.concept-intro__add02 {
  position: absolute;
  z-index: 3;
}
@media (min-width: 1057px) {
  .concept-intro__add02 {
    width: 400px;
    left: calc(50% - 64px);
    top: 460px;
  }
}
@media (max-width: 1056px) {
  .concept-intro__add02 {
    width: 44%;
    top: 23vw;
    right: -2%;
  }
}
.concept-intro__add02__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  border-radius: 50%;
  overflow: hidden;
}
.concept-intro__add02__inner::before {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
}
.concept-intro__add02__inner > div,
.concept-intro__add02__inner figure,
.concept-intro__add02__inner a,
.concept-intro__add02__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.concept-problem {
  padding: clamp(2rem, 4vw, 4rem) 0 clamp(4rem, 6vw, 6rem);
  position: relative;
  z-index: 2;
}
.concept-problem::after {
  content: "";
  width: 100vw;
  height: 5.2vw;
  background: url(../img/common/white_wave.svg) left bottom/cover no-repeat;
  position: absolute;
  left: 0;
  bottom: calc(100% - 2px);
}
.concept-problem::before {
  position: absolute;
  top: calc(100% - 3px);
  left: calc(50% - 60px);
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 45px 60px 0 60px;
  border-color: #fff transparent transparent transparent;
}
@media (min-width: 768px) {
  .concept-problem::before {
    left: calc(50% - 80px);
    border-width: 60px 80px 0 80px;
  }
}
.concept-problem__list {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .concept-problem__list {
    column-gap: 4%;
    row-gap: 4vw;
  }
  .concept-problem__list > * {
    width: 48%;
  }
}
@media (min-width: 1057px) {
  .concept-problem__list {
    column-gap: 2%;
    row-gap: 2rem;
  }
  .concept-problem__list > * {
    width: 23.5%;
  }
}
@media (max-width: 767px) {
  .concept-problem__list {
    flex-direction: column;
    row-gap: 1.5rem;
  }
}

.concept-problem__box {
  background: #f6f6f6;
  border-radius: clamp(8px, 1vw, 1em);
  padding: 2em 5%;
}
@media (min-width: 768px) {
  .concept-problem__box {
    padding: 2em 2.5em;
  }
}
.concept-problem__box__tit {
  color: #2CABED;
  margin-bottom: 1rem;
  font-size: 1.05em;
}
@media (max-width: 1056px) {
  .concept-problem__box__tit {
    font-size: 1.2em;
  }
}
@media (max-width: 767px) {
  .concept-problem__box__tit {
    font-size: 1.28em;
  }
}
@media (min-width: 1057px) {
  .concept-problem__box__txt {
    font-size: 0.88em;
  }
}
@media (min-width: 1057px) and (max-width: 1056px) {
  .concept-problem__box__txt {
    font-size: 1em;
  }
}
@media (min-width: 1057px) and (max-width: 767px) {
  .concept-problem__box__txt {
    font-size: 1.07em;
  }
}
.concept-problem__box__txt ul {
  text-align: left;
}
.concept-problem__box__txt ul li {
  line-height: 1.4;
  padding-left: 1.5rem;
  position: relative;
}
.concept-problem__box__txt ul li::before {
  content: "●";
  color: #2CABED;
  position: absolute;
  left: 0;
  top: 0.2em;
  font-size: 0.8em;
}
.concept-problem__box__txt ul li:not(:last-child) {
  margin-bottom: 0.75rem;
}

.concept-feature {
  background: #E9F7FF;
  padding-top: clamp(5rem, 10vw, 10rem);
  padding-bottom: clamp(8rem, 14vw, 14rem);
}
.concept-feature__tit {
  text-align: center;
  margin-bottom: calc(2rem + 1.5em);
  color: #158EEA;
}
.concept-feature__tit span {
  display: block;
  font-size: calc(1.14rem + 10 * (100vw - 280px) / 1220);
}
@media (min-width: 1557px) {
  .concept-feature__tit span {
    font-size: 1.75rem;
  }
}
.concept-feature__tit b {
  display: block;
  font-size: calc(1.75rem + 20 * (100vw - 280px) / 1220);
}
@media (min-width: 1557px) {
  .concept-feature__tit b {
    font-size: 3rem;
  }
}
.concept-feature__list {
  counter-reset: feature-num;
}

.concept-feature__box {
  position: relative;
}
.concept-feature__box:not(:last-of-type) {
  margin-bottom: clamp(3rem, 8vw, 8rem);
}
@media (min-width: 1057px) {
  .concept-feature__box.is-box01 .concept-feature__box__tit__inner span, .concept-feature__box.is-box03 .concept-feature__box__tit__inner span, .concept-feature__box.is-box05 .concept-feature__box__tit__inner span {
    padding-right: 1em;
  }
  .concept-feature__box.is-box01 .concept-feature__box__tit__inner span::after, .concept-feature__box.is-box03 .concept-feature__box__tit__inner span::after, .concept-feature__box.is-box05 .concept-feature__box__tit__inner span::after {
    left: 100%;
  }
}
@media (min-width: 768px) {
  .concept-feature__box.is-box02 .concept-feature__box__flex, .concept-feature__box.is-box04 .concept-feature__box__flex, .concept-feature__box.is-box06 .concept-feature__box__flex {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1057px) {
  .concept-feature__box.is-box02 .concept-feature__box__tit__inner, .concept-feature__box.is-box04 .concept-feature__box__tit__inner, .concept-feature__box.is-box06 .concept-feature__box__tit__inner {
    flex-direction: row-reverse;
  }
  .concept-feature__box.is-box02 .concept-feature__box__tit__inner span, .concept-feature__box.is-box04 .concept-feature__box__tit__inner span, .concept-feature__box.is-box06 .concept-feature__box__tit__inner span {
    min-width: 50%;
    padding-left: 1em;
  }
  .concept-feature__box.is-box02 .concept-feature__box__tit__inner span::after, .concept-feature__box.is-box04 .concept-feature__box__tit__inner span::after, .concept-feature__box.is-box06 .concept-feature__box__tit__inner span::after {
    right: 100%;
  }
}
.concept-feature__box.is-box02 .concept-feature__box__img {
  display: flex;
}
@media (min-width: 576px) and (max-width: 767px) {
  .concept-feature__box.is-box02 .concept-feature__box__img {
    flex-wrap: wrap;
    column-gap: 2%;
  }
  .concept-feature__box.is-box02 .concept-feature__box__img > * {
    width: 49%;
  }
}
@media (min-width: 768px) and (max-width: 1056px) {
  .concept-feature__box.is-box02 .concept-feature__box__img {
    flex-direction: column;
    row-gap: 1rem;
  }
}
@media (min-width: 1057px) {
  .concept-feature__box.is-box02 .concept-feature__box__img {
    flex-wrap: wrap;
    column-gap: 4%;
  }
  .concept-feature__box.is-box02 .concept-feature__box__img > * {
    width: 48%;
  }
}
@media (max-width: 575px) {
  .concept-feature__box.is-box02 .concept-feature__box__img {
    flex-direction: column;
    row-gap: 1rem;
  }
}

.concept-feature__box__tit {
  overflow: hidden;
  margin-bottom: clamp(1.5rem, 2vw, 2.5rem);
}
.concept-feature__box__tit__inner {
  display: flex;
}
.concept-feature__box__tit__inner span {
  position: relative;
  display: inline-block;
  font-size: calc(1rem + 16 * (100vw - 280px) / 1220);
  font-weight: 700;
  max-width: 95%;
}
@media (min-width: 1557px) {
  .concept-feature__box__tit__inner span {
    font-size: 2rem;
  }
}
@media (max-width: 1056px) {
  .concept-feature__box__tit__inner span {
    padding-right: 1em;
  }
}
.concept-feature__box__tit__inner span em {
  font-style: normal;
  position: relative;
}
.concept-feature__box__tit__inner span em::before {
  counter-increment: feature-num;
  content: counter(feature-num, decimal-leading-zero);
  font-family: 'Akshar', sans-serif;
  font-weight: 600;
  display: inline-block;
  color: #158EEA;
  padding-right: 0.2em;
  font-size: calc(2.5rem + 40 * (100vw - 280px) / 1220);
  vertical-align: middle;
}
@media (min-width: 1557px) {
  .concept-feature__box__tit__inner span em::before {
    font-size: 5rem;
  }
}
@media (max-width: 767px) {
  .concept-feature__box__tit__inner span em::before {
    padding-right: 0.5em;
  }
}
@media (max-width: 767px) {
  .concept-feature__box__tit__inner span em::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    width: 100vw;
    height: 1px;
    background: #158EEA;
    left: 100%;
  }
}
@media (max-width: 767px) {
  .concept-feature__box__tit__inner span b {
    display: block;
  }
}
@media (min-width: 768px) {
  .concept-feature__box__tit__inner span::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    width: 100vw;
    height: 1px;
    background: #158EEA;
  }
}
@media (min-width: 768px) and (max-width: 1056px) {
  .concept-feature__box__tit__inner span::after {
    left: 100%;
  }
}

.concept-feature__box__flex {
  display: flex;
}
@media (min-width: 768px) {
  .concept-feature__box__flex {
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .concept-feature__box__flex {
    flex-direction: column;
    row-gap: 2rem;
  }
}
@media (min-width: 768px) {
  .concept-feature__box__txt {
    width: 48%;
  }
}
@media (min-width: 768px) {
  .concept-feature__box__img {
    width: 42%;
  }
}
@media (max-width: 767px) {
  .concept-feature__box__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .concept-feature__box__img__inner::before {
    content: "";
    display: block;
    padding-top: 66.66%;
    position: relative;
  }
  .concept-feature__box__img__inner > div,
  .concept-feature__box__img__inner figure,
  .concept-feature__box__img__inner a,
  .concept-feature__box__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
.concept-feature__box__img__inner div {
  border-radius: 8px;
  overflow: hidden;
}

.concept-feature__box__grid {
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
@media (min-width: 1057px) {
  .concept-feature__box__grid {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-template-rows: auto auto auto 1fr;
    column-gap: 4%;
    row-gap: 2rem;
  }
}
@media (max-width: 1056px) {
  .concept-feature__box__grid {
    display: flex;
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}

@media (min-width: 1057px) {
  .cost-box.is-cost01 {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .cost-box.is-cost02 {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .cost-box.is-cost03 {
    grid-column: 2/3;
    grid-row: 1/5;
  }
  .cost-box.is-cost04 {
    grid-column: 1/2;
    grid-row: 3/4;
  }
  .cost-box.is-cost05 {
    grid-column: 1/2;
    grid-row: 4/5;
  }
}
.cost-box__tit {
  line-height: 1.4;
  padding-left: 1.5rem;
  position: relative;
  margin-bottom: 1rem;
  font-size: calc(0.94rem + 3 * (100vw - 280px) / 1220);
}
@media (min-width: 1557px) {
  .cost-box__tit {
    font-size: 1.13rem;
  }
}
.cost-box__tit::before {
  content: "●";
  color: #2CABED;
  position: absolute;
  left: 0;
  top: 0.2em;
  font-size: 0.8em;
}
.cost-box__flex {
  display: flex;
  flex-wrap: wrap;
  row-gap: 2rem;
}
@media (min-width: 576px) {
  .cost-box__flex {
    column-gap: 4%;
  }
  .cost-box__flex > * {
    width: 48%;
  }
}
@media (max-width: 575px) {
  .cost-box__flex {
    flex-direction: column;
  }
}

.cost-box__sub__inner {
  display: flex;
  flex-direction: column-reverse;
  row-gap: 1rem;
}
.cost-box__sub__tit {
  margin-bottom: 0.75em;
  font-weight: 700;
}
@media (max-width: 575px) {
  .cost-box__sub__tit {
    font-size: 0.94em;
  }
}
@media (max-width: 575px) and (max-width: 1056px) {
  .cost-box__sub__tit {
    font-size: 1.06em;
  }
}
@media (max-width: 575px) and (max-width: 767px) {
  .cost-box__sub__tit {
    font-size: 1.14em;
  }
}
.cost-box__sub .txt {
  font-size: 0.88em;
}
@media (max-width: 1056px) {
  .cost-box__sub .txt {
    font-size: 1em;
  }
}
@media (max-width: 767px) {
  .cost-box__sub .txt {
    font-size: 1.07em;
  }
}
@media (min-width: 576px) {
  .cost-box__sub .txt {
    font-size: 0.82em;
  }
}
@media (min-width: 576px) and (max-width: 1056px) {
  .cost-box__sub .txt {
    font-size: 0.93em;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .cost-box__sub .txt {
    font-size: 1em;
  }
}
.cost-box__sub__img__inner div {
  border-radius: 8px;
  overflow: hidden;
}

.concept-recommend {
  padding-top: clamp(2rem, 4vw, 4rem);
  position: relative;
  z-index: 2;
}
.concept-recommend::after {
  content: "";
  width: 100vw;
  height: 5.2vw;
  background: url(../img/common/white_wave.svg) left bottom/cover no-repeat;
  position: absolute;
  left: 0;
  bottom: calc(100% - 2px);
}
@media (min-width: 1057px) {
  .concept-recommend__list {
    padding-top: 2rem;
  }
}

.concept-recommend__box {
  margin-bottom: clamp(2rem, 4vw, 4rem);
}
.concept-recommend__box__inner {
  display: flex;
  flex-direction: column-reverse;
}
.concept-recommend__box__txt {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10%;
  padding-bottom: 1.5rem;
  border-left: 1px solid #2CABED;
}
.concept-recommend__box__tit {
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 1.17em;
}
@media (max-width: 1056px) {
  .concept-recommend__box__tit {
    font-size: 1.33em;
  }
}
@media (max-width: 767px) {
  .concept-recommend__box__tit {
    font-size: 1.42em;
  }
}
@media (min-width: 1057px) {
  .concept-recommend__box__tit {
    font-size: calc(1rem + 4 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1557px) {
  .concept-recommend__box__tit {
    font-size: 1.25rem;
  }
}
.concept-recommend__box__img {
  margin-bottom: clamp(1.25rem, 2vw, 2rem);
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 375px) {
  .concept-recommend__box__img {
    width: 80%;
  }
}
.concept-recommend__box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #E5E9EC;
}
.concept-recommend__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
}
.concept-recommend__box__img__inner > div,
.concept-recommend__box__img__inner figure,
.concept-recommend__box__img__inner a,
.concept-recommend__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/*--------------------------------------------------------------------/
	product
/--------------------------------------------------------------------*/
.product-intro {
  color: #fff;
  padding-top: clamp(2rem, 3vw, 3rem);
  padding-bottom: clamp(4rem, 9vw, 9rem);
}
.product-intro__inner {
  display: flex;
  justify-content: center;
}
.product-intro__catch {
  font-weight: 600;
}
.product-intro__catch .is-catch01 {
  font-size: calc(0.94rem + 14 * (100vw - 280px) / 1220);
}
@media (min-width: 1557px) {
  .product-intro__catch .is-catch01 {
    font-size: 1.88rem;
  }
}
.product-intro__catch .is-catch02 {
  font-size: calc(1.4rem + 24 * (100vw - 280px) / 1220);
}
@media (min-width: 1557px) {
  .product-intro__catch .is-catch02 {
    font-size: 3rem;
  }
}
.product-intro__catch .is-catch03 {
  font-size: calc(0.94rem + 14 * (100vw - 280px) / 1220);
}
@media (min-width: 1557px) {
  .product-intro__catch .is-catch03 {
    font-size: 1.88rem;
  }
}

.product-point {
  padding-bottom: clamp(2rem, 10vw, 12rem);
}
@media (min-width: 1557px) {
  .product-point {
    padding-top: clamp(5rem, 9vw, 9rem);
  }
}
@media (max-width: 1556px) {
  .product-point__inner {
    padding: clamp(2rem, 4vw, 4rem) 5% clamp(2rem, 3vw, 3rem);
    border-radius: clamp(16px, 2vw, 2em);
    background: #fff;
  }
}
.product-point__tit {
  margin-bottom: calc(1.5rem + 0.5em);
  font-size: calc(1.14rem + 16 * (100vw - 280px) / 1220);
  font-weight: 600;
  position: relative;
  color: #158EEA;
}
@media (min-width: 1557px) {
  .product-point__tit {
    font-size: 2.25rem;
  }
}
.product-point__tit::before {
  content: "";
  width: 100%;
  height: 2px;
  background: #158EEA;
  position: absolute;
  left: 0;
  z-index: 1;
  top: calc(50% - 1px);
}
.product-point__tit span {
  position: relative;
  display: inline-block;
  background: #fff;
  padding-right: 1em;
  z-index: 2;
}
.product-point__list {
  display: flex;
  counter-reset: point-num;
}
@media (min-width: 1057px) {
  .product-point__list {
    flex-wrap: wrap;
    column-gap: 6.66%;
    row-gap: 2rem;
  }
  .product-point__list > * {
    width: 43.33%;
  }
  .product-point__list > *:nth-of-type(4n - 1) {
    margin-left: 6.66%;
  }
}
@media (max-width: 1056px) {
  .product-point__list {
    flex-direction: column;
  }
}

.product-point__box__inner {
  position: relative;
  padding-left: 90px;
  padding-bottom: 2rem;
}
@media (min-width: 768px) {
  .product-point__box__inner {
    padding-left: 150px;
  }
}
.product-point__box__txt {
  padding-top: 20px;
}
@media (min-width: 768px) {
  .product-point__box__txt {
    padding-top: 50px;
  }
}
.product-point__box__tit {
  font-weight: 700;
  margin-bottom: calc(0.75rem + 0.3em);
  font-size: calc(1rem + 4 * (100vw - 280px) / 1220);
}
@media (min-width: 1057px) {
  .product-point__box__tit {
    font-size: calc(1rem + 4 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1557px) {
  .product-point__box__tit {
    font-size: 1.25rem;
  }
}
.product-point__box__num {
  position: absolute;
  top: 0;
  left: 0;
}
.product-point__box__num__inner {
  background: #2CABED;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .product-point__box__num__inner {
    width: 120px;
    height: 120px;
  }
}
.product-point__box__num__inner span {
  font-family: 'Gantari', sans-serif;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.1;
  font-size: 0.75rem;
}
@media (min-width: 768px) {
  .product-point__box__num__inner span {
    font-size: 1.13rem;
  }
}
.product-point__box__num__inner span::after {
  counter-increment: point-num;
  content: counter(point-num, decimal-leading-zero);
  font-family: 'Akshar', sans-serif;
  font-weight: 600;
  display: block;
  font-size: 2rem;
}
@media (min-width: 768px) {
  .product-point__box__num__inner span::after {
    font-size: 3.25rem;
  }
}

.product-patent {
  padding-top: clamp(2rem, 5.2vw, 5.2vw);
  padding-bottom: clamp(4rem, 12vw, 12rem);
  background: #F7F9FB;
  position: relative;
}
.product-patent::after {
  content: "";
  width: 100vw;
  height: 5.2vw;
  background: url(../img/common/lightgray_wave.svg) left bottom/cover no-repeat;
  position: absolute;
  left: 0;
  bottom: calc(100% - 2px);
}
.product-patent__tit {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: calc(1.5rem + 1em);
}
.product-patent__tit img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.product-patent__list {
  padding-top: clamp(1rem, 2vw, 2rem);
  max-width: 1590px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1057px) {
  .product-patent__list {
    column-gap: 1.88%;
    row-gap: clamp(1rem, 1.88vw, 1.75rem);
  }
}
@media (max-width: 1056px) {
  .product-patent__list {
    column-gap: 3%;
    row-gap: 3vw;
  }
}
.product-patent__img {
  width: 31.33%;
}
@media (min-width: 1057px) {
  .product-patent__img {
    width: 15.09%;
  }
}
.product-patent__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
}
.product-patent__img__inner::before {
  content: "";
  display: block;
  padding-top: 141.66%;
  position: relative;
}
.product-patent__img__inner > div,
.product-patent__img__inner figure,
.product-patent__img__inner a,
.product-patent__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.product-sdgs {
  position: relative;
  padding: clamp(3rem, 9vw, 9rem) 0;
  background: url(../img/lv2/product_sdgs_bg.jpg) center center/cover no-repeat;
}
.product-sdgs::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(5, 71, 105, 0.64);
  z-index: 1;
}
.product-sdgs__inner {
  position: relative;
  z-index: 2;
  border-radius: clamp(16px, 2vw, 2em);
  background: #fff;
  padding: 2em 5%;
}
@media (min-width: 768px) {
  .product-sdgs__inner {
    padding: 3em 8%;
  }
}
@media (min-width: 1057px) {
  .product-sdgs__inner {
    padding: 3em 10%;
  }
}
.product-sdgs__tit {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: calc(1.5rem + 1em);
}
.product-sdgs__tit img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.product-sdgs__img {
  margin-bottom: clamp(1rem, 2vw, 2rem);
}
@media (min-width: 1057px) {
  .product-sdgs__txt {
    display: flex;
    column-gap: 4%;
  }
  .product-sdgs__txt > * {
    max-width: 48%;
  }
}
.product-sdgs__txt ul li span {
  display: inline-block;
  min-width: 4rem;
  font-weight: 600;
}
@media (min-width: 768px) {
  .product-sdgs__txt ul li span {
    min-width: 6rem;
  }
}

.product-outline {
  padding-top: clamp(5rem, 9vw, 9rem);
}

.product-box:not(:last-of-type) {
  margin-bottom: clamp(3rem, 6vw, 6rem);
}
.product-box__flex {
  display: flex;
  padding-bottom: clamp(2rem, 3vw, 3rem);
}
@media (min-width: 768px) {
  .product-box__flex {
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .product-box__flex {
    flex-direction: column;
    row-gap: 2rem;
  }
}
.product-box__table {
  border-top: 1px solid #C9CED2;
}
@media (min-width: 768px) {
  .product-box__table {
    width: 50%;
  }
}
.product-box__table table {
  width: 100%;
}
.product-box__table table caption {
  text-align: left;
  font-size: 1.05em;
  padding: 1rem 0;
  font-weight: 700;
  border-bottom: 1px solid #C9CED2;
}
@media (max-width: 1056px) {
  .product-box__table table caption {
    font-size: 1.2em;
  }
}
@media (max-width: 767px) {
  .product-box__table table caption {
    font-size: 1.28em;
  }
}
.product-box__table table tr {
  border-bottom: 1px solid #C9CED2;
}
.product-box__table table th, .product-box__table table td.column-1 {
  text-align: left;
  font-weight: normal;
  padding: 1rem 0;
  width: 25%;
  min-width: 120px;
  font-weight: 700;
}
.product-box__table table td {
  padding: 1rem 0 1rem 1rem;
}
@media (min-width: 768px) {
  .product-box__img {
    width: 36.92%;
  }
}
.product-box__img__inner img {
  border-radius: clamp(8px, 1vw, 1em);
  border: 1px solid #C9CED2;
  overflow: hidden;
}
.product-box__list {
  padding-bottom: clamp(2rem, 3vw, 3rem);
}
.product-box__list__img {
  margin-bottom: 2rem;
}
.product-box__list__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
}
.product-box__list__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.product-box__list__img__inner > div,
.product-box__list__img__inner figure,
.product-box__list__img__inner a,
.product-box__list__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.product-box__list__img__inner img {
  overflow: hidden;
  border-radius: clamp(8px, 1vw, 1em);
  border: 1px solid #C9CED2;
}
.product-box__list__img figcaption {
  margin-top: 1rem;
}

/*--------------------------------------------------------------------/
	method
/--------------------------------------------------------------------*/
/* method-video */
.method-video {
  padding-top: clamp(2rem, 3vw, 3rem);
  margin-bottom: clamp(5rem, 9vw, 9rem);
}
.method-video__list {
  width: 100%;
}
@media (min-width: 768px) {
  .method-video__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.method-video__video {
  width: 100%;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .method-video__video {
    width: 48%;
  }
}
.method-video__video__inner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  border-radius: clamp(8px, 1vw, 1em);
  z-index: 1;
  border: 1px solid #C9CED2;
}
.method-video__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.method-video__txt .txt {
  padding: 1.5rem 5%;
  background: #F7F9FB;
  border-radius: clamp(8px, 1vw, 1em);
}

/* method-flow */
.method-flow {
  overflow: hidden;
  margin-bottom: clamp(5rem, 9vw, 9rem);
}
.method-flow__tit {
  color: #158EEA;
}
.method-flow__tit span {
  display: inline-block;
  padding-right: 0.75em;
  position: relative;
  font-size: calc(1.25rem + 12 * (100vw - 280px) / 1220);
  font-weight: 700;
  margin-bottom: calc(1.5rem + 0.5em);
}
@media (min-width: 1557px) {
  .method-flow__tit span {
    font-size: 2rem;
  }
}
.method-flow__tit span::after {
  content: "";
  width: 100vw;
  height: 2px;
  background: #158EEA;
  position: absolute;
  top: calc(50% - 1px);
  left: 100%;
}
.method-flow__list {
  counter-reset: flow-num;
}
@media (min-width: 768px) {
  .method-flow__list {
    padding-top: clamp(1rem, 2vw, 2rem);
  }
}

.method-flow__box {
  position: relative;
}
.method-flow__box:not(:last-of-type) {
  padding-bottom: clamp(2rem, 4vw, 4rem);
}
.method-flow__box:not(:last-of-type)::before {
  position: absolute;
  top: 20px;
  left: 34px;
  content: "";
  background-size: 2px 10px;
  width: 2px;
  height: 100%;
  background-image: linear-gradient(to bottom, #2CABED, #2CABED 7px, transparent 7px, transparent 3px);
}
@media (min-width: 768px) {
  .method-flow__box:not(:last-of-type)::before {
    left: 59px;
    background-size: 3px 15px;
    width: 3px;
    background-image: linear-gradient(to bottom, #2CABED, #2CABED 10px, transparent 10px, transparent 5px);
  }
}
.method-flow__box__inner {
  position: relative;
  padding-left: 90px;
  padding-bottom: 2rem;
}
@media (min-width: 768px) {
  .method-flow__box__inner {
    padding-left: 150px;
  }
}
.method-flow__box__txt {
  padding-top: 20px;
}
@media (min-width: 768px) {
  .method-flow__box__txt {
    padding-top: 30px;
  }
}
.method-flow__box__tit {
  font-weight: 700;
  font-size: calc(1rem + 8 * (100vw - 280px) / 1220);
  margin-bottom: 1rem;
}
@media (min-width: 1557px) {
  .method-flow__box__tit {
    font-size: 1.5rem;
  }
}
.method-flow__box__num {
  position: absolute;
  top: 0;
  left: 0;
}
.method-flow__box__num__inner {
  background: #2CABED;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .method-flow__box__num__inner {
    width: 120px;
    height: 120px;
  }
}
.method-flow__box__num__inner span {
  font-family: 'Gantari', sans-serif;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.1;
  font-size: 0.75rem;
}
@media (min-width: 768px) {
  .method-flow__box__num__inner span {
    font-size: 1.13rem;
  }
}
.method-flow__box__num__inner span::after {
  counter-increment: flow-num;
  content: counter(flow-num, decimal-leading-zero);
  font-family: 'Akshar', sans-serif;
  font-weight: 600;
  display: block;
  font-size: 2rem;
}
@media (min-width: 768px) {
  .method-flow__box__num__inner span::after {
    font-size: 3.25rem;
  }
}

/* method-partner */
.method-partner a {
  position: relative;
  text-decoration: none !important;
  color: #fff;
  border-radius: clamp(8px, 1vw, 1em);
  padding: 1.5rem 10% clamp(2rem, 3vw, 3rem);
  display: block;
  transition: all 0.3s ease 0s;
  background: url(../img/top/top_partner_bg.jpg) center center/cover no-repeat;
}
@media (min-width: 768px) {
  .method-partner a {
    padding: 2rem 10% clamp(2rem, 3vw, 3rem);
  }
}
.method-partner a::before {
  border-radius: clamp(8px, 1vw, 1em);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(5, 71, 105, 0.64);
}
.method-partner a:hover {
  color: #2CABED;
}
.method-partner__inner {
  position: relative;
  z-index: 4;
}
.method-partner__tit {
  line-height: 1.2;
  margin-bottom: 1rem;
}
.method-partner__tit em {
  font-family: 'Gantari', sans-serif;
  font-style: normal;
  display: block;
  font-size: calc(2rem + 36 * (100vw - 280px) / 1220);
  font-weight: 700;
}
@media (min-width: 1557px) {
  .method-partner__tit em {
    font-size: 4.38rem;
  }
}
.method-partner__tit span {
  font-weight: 700;
  font-size: calc(1rem + 8 * (100vw - 280px) / 1220);
}
@media (min-width: 1557px) {
  .method-partner__tit span {
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) {
  .method-partner__txt {
    font-size: 0.88em;
  }
}
@media (min-width: 768px) and (max-width: 1056px) {
  .method-partner__txt {
    font-size: 1em;
  }
}
@media (min-width: 768px) and (max-width: 767px) {
  .method-partner__txt {
    font-size: 1.07em;
  }
}

/*--------------------------------------------------------------------/
	cases
/--------------------------------------------------------------------*/
.cases-intro {
  padding: 2rem 4%;
  background: #f6f6f6;
  font-size: 1.05em;
  font-weight: 500;
}
@media (max-width: 1056px) {
  .cases-intro {
    font-size: 1.2em;
  }
}
@media (max-width: 767px) {
  .cases-intro {
    font-size: 1.28em;
  }
}
@media (min-width: 1057px) {
  .cases-intro {
    padding: 2rem 3rem;
    text-align: center;
  }
}

/* スライダーを使用しない場合の一枚画像　*/
.cases-single__main {
  position: relative;
  max-width: 1040px;
  height: auto;
  margin-bottom: 2rem;
}
.cases-single__main__img {
  position: relative;
  width: 100%;
  z-index: 1;
}
.cases-single__main__img::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.cases-single__main__img > div,
.cases-single__main__img figure,
.cases-single__main__img a,
.cases-single__main__img picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cases-single__main figcaption {
  position: absolute;
  bottom: 0;
  right: 0.5rem;
  display: inline-block;
  background: #000;
  color: #fff;
  line-height: 1.4;
  padding: 0.2rem 0.4rem;
  font-size: 0.8em;
  z-index: 1;
}

/* cases-slider */
.cases-slider {
  position: relative;
  height: auto;
  margin-bottom: 2rem;
}
@media (min-width: 1057px) {
  .cases-slider {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/common/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 30px;
  width: 30px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  top: 29.5vw;
  transform: translateY(-50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 4;
  /*&.slick-disabled:before {
      opacity: $slick-opacity-not-active;
  }
  &:before {
      font-family: $slick-font-family;
      font-size: 20px;
      line-height: 1;
      color: $slick-arrow-color;
      opacity: $slick-opacity-default;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }*/
}
@media (min-width: 1057px) {
  .slick-prev,
  .slick-next {
    height: 40px;
    width: 40px;
    top: 18vw;
  }
}
@media (min-width: 1557px) {
  .slick-prev,
  .slick-next {
    top: 260px;
  }
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev {
  left: -15px;
}
@media (min-width: 1057px) {
  .slick-prev {
    left: -20px;
  }
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -15px;
}
@media (min-width: 1057px) {
  [dir=rtl] .slick-prev {
    right: -20px;
  }
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -15px;
}
@media (min-width: 1057px) {
  .slick-next {
    right: -20px;
  }
}
[dir=rtl] .slick-next {
  left: -15px;
  right: auto;
}
@media (min-width: 1057px) {
  [dir=rtl] .slick-next {
    left: -20px;
  }
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* gallery-top */
.gallery-top {
  position: relative;
}
@media (min-width: 1057px) {
  .gallery-top {
    width: 60%;
    max-width: 780px;
  }
}
@media (max-width: 1056px) {
  .gallery-top {
    margin-bottom: 1.5rem;
  }
}
.gallery-top__inner {
  width: 100%;
  position: relative;
}
.gallery-top .slick-arrow {
  border: 1px solid #C9CED2;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  transition: all 0.3s ease 0s;
  border-radius: 50%;
}
.gallery-top .slick-arrow:hover {
  background: rgba(21, 142, 234, 0.8);
}
.gallery-top .slick-arrow i {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  width: 30px;
  height: 30px;
}
@media (min-width: 1057px) {
  .gallery-top .slick-arrow i {
    width: 40px;
    height: 40px;
  }
}
.gallery-top .slick-arrow i::before {
  color: #C9CED2;
}
.gallery-top .slick-prev::before {
  display: none;
}
.gallery-top .slick-next::before {
  display: none;
}
.gallery-top .sp-slide {
  position: relative;
  overflow: hidden;
}
.gallery-top .sp-slide__inner {
  position: relative;
  width: 100%;
  z-index: 1;
}
.gallery-top .sp-slide__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.gallery-top .sp-slide__inner > div,
.gallery-top .sp-slide__inner figure,
.gallery-top .sp-slide__inner a,
.gallery-top .sp-slide__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.gallery-top .sp-slide__inner a {
  border-radius: clamp(8px, 1vw, 1em);
}
.gallery-top .sp-slide__inner img {
  border-radius: clamp(8px, 1vw, 1em);
}
.gallery-top .sp-slide figcaption {
  margin-top: 0.75rem;
  color: #202020;
  line-height: 1.4;
  z-index: 1;
}

/* gallery-thumbs */
.gallery-thumbs {
  position: relative;
}
@media (min-width: 1057px) {
  .gallery-thumbs {
    width: 30%;
  }
}
.gallery-thumbs__inner {
  width: 100%;
  position: relative;
}

.gallery-thumbs__list {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1057px) {
  .gallery-thumbs__list {
    column-gap: 5%;
    row-gap: 1.6vw;
  }
  .gallery-thumbs__list > * {
    width: 30%;
  }
}
@media (min-width: 1557px) {
  .gallery-thumbs__list {
    row-gap: 19px;
  }
}
@media (max-width: 1056px) {
  .gallery-thumbs__list {
    column-gap: 2%;
    row-gap: 1.95vw;
  }
  .gallery-thumbs__list > * {
    width: 15%;
  }
}
.gallery-thumbs__list .sp-thumbnail {
  transition: all 0.3s ease 0s;
  border: 1px solid #E5E9EC;
  border-radius: 8px;
  overflow: hidden;
}
.gallery-thumbs__list .sp-thumbnail img {
  transition: all 0.3s ease 0s;
}
.gallery-thumbs__list .sp-thumbnail:hover img {
  transform: scale(1.1, 1.1);
}
.gallery-thumbs__list .sp-thumbnail__inner {
  position: relative;
  width: 100%;
  z-index: 1;
}
.gallery-thumbs__list .sp-thumbnail__inner::before {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
}
.gallery-thumbs__list .sp-thumbnail__inner > div,
.gallery-thumbs__list .sp-thumbnail__inner figure,
.gallery-thumbs__list .sp-thumbnail__inner a,
.gallery-thumbs__list .sp-thumbnail__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cases-main__img {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.cases-main__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
}
.cases-main__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.cases-main__img__inner > div,
.cases-main__img__inner figure,
.cases-main__img__inner a,
.cases-main__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cases-main__img__inner img {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: clamp(8px, 1vw, 1em);
}
.cases-main__img figcaption {
  margin-top: 0.7rem;
}

.cases-ba {
  padding-top: clamp(3rem, 6vw, 6rem);
}
.cases-ba__inner {
  border: 2px solid #2CABED;
  position: relative;
  border-radius: clamp(8px, 1vw, 1em);
  padding: 3em 5% 2em;
  background: #fff;
}
@media (min-width: 1557px) {
  .cases-ba__inner {
    padding: 4em 8% 3em;
  }
}
.cases-ba__tit {
  position: absolute;
  bottom: calc(100% - 0.5em);
  line-height: 1;
  font-size: calc(1.25rem + 24 * (100vw - 280px) / 1220);
}
@media (min-width: 1557px) {
  .cases-ba__tit {
    font-size: 3rem;
  }
}
.cases-ba__tit span {
  font-family: 'Gantari', sans-serif;
  font-weight: 600;
  padding: 0 0.75em;
  background: #fff;
}
.cases-ba__tit span i {
  color: #2CABED;
}

.cases-ba__box:not(:last-of-type) {
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
.cases-ba__box__inner {
  display: flex;
}
@media (min-width: 768px) {
  .cases-ba__box__inner {
    justify-content: space-between;
  }
  .cases-ba__box__inner > * {
    width: 44%;
  }
}
@media (max-width: 767px) {
  .cases-ba__box__inner {
    flex-direction: column;
  }
}
.cases-ba__box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  border-radius: clamp(8px, 1vw, 1em);
}
.cases-ba__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.cases-ba__box__img__inner > div,
.cases-ba__box__img__inner figure,
.cases-ba__box__img__inner a,
.cases-ba__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cases-ba__box__before {
  position: relative;
}
@media (min-width: 768px) {
  .cases-ba__box__before:after {
    content: "\e315";
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
@media (max-width: 767px) {
  .cases-ba__box__before {
    margin-bottom: 3.5rem;
  }
  .cases-ba__box__before:after {
    content: "\e313";
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
.cases-ba__box__before::after {
  position: absolute;
  color: #2CABED;
}
@media (min-width: 768px) {
  .cases-ba__box__before::after {
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 1vw);
    font-size: 4.7em;
  }
}
@media (min-width: 768px) and (max-width: 1056px) {
  .cases-ba__box__before::after {
    font-size: 5.33em;
  }
}
@media (min-width: 768px) and (max-width: 767px) {
  .cases-ba__box__before::after {
    font-size: 5.71em;
  }
}
@media (min-width: 1557px) {
  .cases-ba__box__before::after {
    left: calc(100% + 1.5rem);
  }
}
@media (max-width: 767px) {
  .cases-ba__box__before::after {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 3.52em;
  }
}
@media (max-width: 767px) and (max-width: 1056px) {
  .cases-ba__box__before::after {
    font-size: 4em;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .cases-ba__box__before::after {
    font-size: 4.28em;
  }
}
.cases-ba__box figcaption {
  margin-top: 0.75rem;
}

.cases-textarea {
  margin-top: clamp(2rem, 3vw, 3rem);
  padding: 2em 5%;
  background: #F7F9FB;
  border-radius: clamp(8px, 1vw, 1em);
}
@media (min-width: 1557px) {
  .cases-textarea {
    padding: 3em 8%;
  }
}

.cases-pdf {
  margin-top: 2rem;
}
@media (min-width: 1057px) {
  .cases-pdf {
    margin-top: 3rem;
  }
}
@media (min-width: 1057px) {
  .cases-pdf__list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2%;
    row-gap: 1rem;
  }
  .cases-pdf__list > * {
    width: 49%;
  }
}
@media (max-width: 1056px) {
  .cases-pdf__list li:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}
.cases-pdf__list li a {
  padding: 1rem 4% 1rem 4rem;
  transition: all 0.3s ease 0s;
  border: 1px solid #C9CED2;
  border-radius: 8px;
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  color: #202020;
  text-decoration: none !important;
}
.cases-pdf__list li a:before {
  content: "\e907";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cases-pdf__list li a::before {
  position: absolute;
  left: 1.25rem;
  font-size: 1.76em;
  color: #C9CED2;
}
@media (max-width: 1056px) {
  .cases-pdf__list li a::before {
    font-size: 2em;
  }
}
@media (max-width: 767px) {
  .cases-pdf__list li a::before {
    font-size: 2.14em;
  }
}
.cases-pdf__list li a:hover {
  background: #979DA4;
  border-color: #979DA4;
  color: #fff;
}

.cases-video {
  padding-top: clamp(3rem, 6vw, 6rem);
}
.cases-video__tit {
  margin-bottom: calc(1.5rem + 0.3em);
  font-size: calc(1.14rem + 8 * (100vw - 280px) / 1220);
  font-weight: 600;
  position: relative;
}
@media (min-width: 1557px) {
  .cases-video__tit {
    font-size: 1.75rem;
  }
}
.cases-video__tit::before {
  content: "";
  width: 100%;
  height: 2px;
  background: #2CABED;
  position: absolute;
  left: 0;
  z-index: 1;
  top: calc(50% - 1px);
}
.cases-video__tit span {
  position: relative;
  display: inline-block;
  background: #fff;
  padding-right: 1em;
  z-index: 2;
}
@media (min-width: 1057px) {
  .cases-video__flex {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 1057px) {
  .cases-video__video {
    flex-basis: 37.5%;
  }
}
@media (max-width: 1056px) {
  .cases-video__video {
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    margin-bottom: 1rem;
  }
}
.cases-video__video__inner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.cases-video__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: clamp(8px, 1vw, 1em);
  overflow: hidden;
}
@media (min-width: 1057px) {
  .cases-video__txt {
    flex-basis: 58%;
  }
}

/* cases-table */
.cases-table {
  padding-top: clamp(3rem, 6vw, 6rem);
}
.cases-table__tit {
  text-align: center;
  font-size: calc(1rem + 8 * (100vw - 280px) / 1220);
  font-weight: 600;
  margin-bottom: calc(1.5rem + 0.3em);
}
@media (min-width: 1557px) {
  .cases-table__tit {
    font-size: 1.5rem;
  }
}

.cases-access__map {
  margin-bottom: 1rem;
  position: relative;
  padding: 0;
  height: 0;
  overflow: hidden;
  background-color: #fff;
}
@media (min-width: 1057px) {
  .cases-access__map {
    padding-bottom: 40%;
  }
}
@media (max-width: 1056px) {
  .cases-access__map {
    padding-bottom: 300px;
  }
}
.cases-access__map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

/*--------------------------------------------------------------------/
	qa
/--------------------------------------------------------------------*/
.qa-intro {
  color: #fff;
  font-size: calc(0.94rem + 4 * (100vw - 280px) / 1220);
  font-weight: 500;
  padding-top: clamp(1rem, 2vw, 2rem);
  margin-bottom: clamp(2rem, 4vw, 3rem);
  counter-reset: q-num;
}
@media (min-width: 1557px) {
  .qa-intro {
    font-size: 1.25rem;
  }
}

.qa-list {
  counter-reset: a-num;
}

.qa-box {
  border-radius: clamp(8px, 1vw, 1em);
  border: 1px solid #B0DEF9;
  overflow: hidden;
}
.qa-box:not(:last-of-type) {
  margin-bottom: clamp(1rem, 2vw, 2rem);
}
.qa-box__inner {
  background: #fff;
  padding: 1.5em 5% 2em;
}
@media (min-width: 768px) {
  .qa-box__inner {
    padding: 1.5em 3em 2.5em;
  }
}
.qa-box__tit {
  position: relative;
  padding-bottom: 1.25em;
  margin-bottom: 1.25em;
  display: flex;
  align-content: center;
  column-gap: 1.25em;
}
.qa-box__tit::before {
  position: absolute;
  left: 0;
  bottom: 0;
  background-size: 8px 1px;
  content: "";
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, #2CABED, #2CABED 3px, transparent 3px, transparent 8px);
}
.qa-box__tit em {
  position: relative;
  flex-shrink: 0;
  font-style: normal;
  display: block;
  color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #2CABED;
  font-family: 'Gantari', sans-serif;
  line-height: 1.1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .qa-box__tit em {
    width: 80px;
    height: 80px;
  }
}
.qa-box__tit em::before {
  content: "Question";
  letter-spacing: 0;
  font-weight: 400;
  font-size: 0.69rem;
  display: block;
}
@media (min-width: 768px) {
  .qa-box__tit em::before {
    font-size: 0.81rem;
  }
}
.qa-box__tit em::after {
  display: block;
  counter-increment: q-num;
  font-weight: 600;
  letter-spacing: 0.03em;
  content: counter(q-num, decimal-leading-zero);
  font-size: 1.25rem;
}
@media (min-width: 768px) {
  .qa-box__tit em::after {
    font-size: 1.88rem;
  }
}
.qa-box__tit b {
  display: flex;
  align-items: center;
  flex-shrink: 1;
  font-size: calc(0.94rem + 4 * (100vw - 280px) / 1220);
}
@media (min-width: 768px) {
  .qa-box__tit b {
    font-size: calc(1.14rem + 4 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1557px) {
  .qa-box__tit b {
    font-size: 1.38rem;
  }
}
.qa-box__txt {
  display: flex;
  align-content: flex-start;
  column-gap: 1.25em;
}
.qa-box__txt > em {
  position: relative;
  flex-shrink: 0;
  font-style: normal;
  display: block;
  color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #3BB7AA;
  font-family: 'Gantari', sans-serif;
  line-height: 1.1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .qa-box__txt > em {
    width: 80px;
    height: 80px;
  }
}
.qa-box__txt > em::before {
  content: "Answer";
  letter-spacing: 0;
  font-weight: 400;
  font-size: 0.69rem;
  display: block;
}
@media (min-width: 768px) {
  .qa-box__txt > em::before {
    font-size: 0.81rem;
  }
}
.qa-box__txt > em::after {
  display: block;
  counter-increment: a-num;
  font-weight: 600;
  letter-spacing: 0.03em;
  content: counter(a-num, decimal-leading-zero);
  font-size: 1.25rem;
}
@media (min-width: 768px) {
  .qa-box__txt > em::after {
    font-size: 1.88rem;
  }
}
.qa-box__txt .txt {
  padding-top: 0.5em;
}

/*--------------------------------------------------------------------/
	blog
/--------------------------------------------------------------------*/
.contents-flex {
  display: flex;
}
@media (min-width: 1057px) {
  .contents-flex {
    justify-content: space-between;
  }
}
@media (max-width: 1056px) {
  .contents-flex {
    flex-direction: column;
    row-gap: clamp(3rem, 5vw, 5rem);
  }
}

.contents-left {
  width: 100%;
}
@media (min-width: 1057px) {
  .contents-left {
    width: 69.33%;
  }
}

.news-box {
  position: relative;
  text-align: left;
}
.news-box:not(:last-of-type) {
  margin-bottom: clamp(1rem, 2vw, 2rem);
}
.news-box a {
  display: block;
  position: relative;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
  border-radius: clamp(8px, 1vw, 1em);
  background: #fff;
  border: 1px solid #B0DEF9;
  height: 100%;
  padding: 1.5em;
}
@media (max-width: 767px) {
  .news-box a {
    padding: 1.5em 5%;
  }
}
.news-box a:hover {
  border-color: #1C3AFF;
}
.news-box__flex {
  display: flex;
}
@media (min-width: 768px) {
  .news-box__flex {
    column-gap: 4%;
  }
}
@media (max-width: 767px) {
  .news-box__flex {
    flex-direction: column;
    row-gap: 1rem;
  }
}
@media (min-width: 768px) {
  .news-box__img {
    width: 30%;
    max-width: 220px;
  }
}
.news-box__img__inner {
  overflow: hidden;
  border-radius: 8px;
}
@media (min-width: 768px) {
  .news-box__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .news-box__img__inner::before {
    content: "";
    display: block;
    padding-top: 75%;
    position: relative;
  }
  .news-box__img__inner > div,
  .news-box__img__inner figure,
  .news-box__img__inner a,
  .news-box__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .news-box__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .news-box__img__inner::before {
    content: "";
    display: block;
    padding-top: 66.66%;
    position: relative;
  }
  .news-box__img__inner > div,
  .news-box__img__inner figure,
  .news-box__img__inner a,
  .news-box__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
.news-box__txt {
  color: #202020;
  position: relative;
}
@media (min-width: 768px) {
  .news-box__txt {
    width: 66%;
    height: 100%;
    flex-shrink: 1;
  }
  .news-box__txt:after {
    content: "";
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .news-box__txt::after {
    position: absolute;
    transition: all 0.3s ease 0s;
    color: #C9CED2;
    right: 2%;
    top: 50%;
    transform: translateY(-50%);
  }
}
.news-box__list {
  margin-bottom: 0.75em;
}
@media (min-width: 576px) {
  .news-box__list {
    display: flex;
  }
}
.news-box__date {
  font-family: 'Gantari', sans-serif;
  font-weight: 600;
  padding-top: 0.2em;
  color: #2CABED;
}
@media (min-width: 576px) {
  .news-box__category {
    padding-left: 0.75em;
  }
}
.news-box__tit {
  font-size: calc(1rem + 3 * (100vw - 280px) / 1220);
  margin-bottom: 0.5rem;
}
@media (min-width: 1057px) {
  .news-box__tit {
    font-size: calc(1rem + 3 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1557px) {
  .news-box__tit {
    font-size: 1.19rem;
  }
}

/*--------------------------------------------------------------------/
	single-page
/--------------------------------------------------------------------*/
.single-cont {
  border-radius: clamp(8px, 1vw, 1em);
  border: 1px solid #B0DEF9;
  background: #fff;
  padding: 2em 5%;
}
@media (min-width: 1057px) {
  .single-cont {
    padding: 1.5em 2em 2em;
  }
}
@media (min-width: 1557px) {
  .single-cont {
    padding: 2em 3em 3em;
  }
}
.single-cont__inner {
  position: relative;
}
.single-cont__inner::after {
  display: block;
  content: "";
  clear: both;
}
.single-cont__addtoany {
  border-top: 1px dotted #C9CED2;
  padding-top: 1.5em;
}
.single-cont__tag {
  padding-top: 1em;
}
.single-cont__tag a {
  font-size: 1rem !important;
  color: #2CABED;
  padding-left: 1.5em;
  margin-right: 0.5rem;
  position: relative;
  text-decoration: none !important;
}
.single-cont__tag a:before {
  content: "\e903";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.single-cont__tag a::before {
  position: absolute;
  top: 0.6em;
  left: 0.2rem;
  font-size: 0.85em;
  color: #2CABED;
  text-decoration: none !important;
}
.single-cont__tag a:hover {
  text-decoration: underline !important;
}

.news-interview {
  padding-bottom: clamp(3rem, 5vw, 5rem);
}
.news-interview__flex {
  display: flex;
}
@media (min-width: 768px) {
  .news-interview__flex {
    justify-content: space-between;
  }
  .news-interview__flex.is-right {
    flex-direction: row-reverse;
  }
}
@media (max-width: 767px) {
  .news-interview__flex {
    flex-direction: column;
    row-gap: 0.75rem;
  }
}
@media (min-width: 768px) {
  .news-interview__flex .news-interview__img {
    width: 38%;
  }
}
.news-interview__flex .news-interview__img__inner {
  border-radius: 8px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .news-interview__flex .news-interview__txt {
    width: 58%;
  }
}

.news-video {
  padding-bottom: clamp(3rem, 5vw, 5rem);
}
.news-video__tit {
  margin-bottom: calc(1.5rem + 0.3em);
  font-size: calc(1rem + 8 * (100vw - 280px) / 1220);
  font-weight: 600;
  position: relative;
  overflow: hidden;
}
@media (min-width: 1557px) {
  .news-video__tit {
    font-size: 1.5rem;
  }
}
.news-video__tit span {
  position: relative;
  display: inline-block;
  padding-right: 1em;
  z-index: 2;
}
.news-video__tit span::before {
  content: "";
  width: 100vw;
  height: 1px;
  background: #C9CED2;
  position: absolute;
  top: 50%;
  left: 100%;
  z-index: 1;
}
@media (min-width: 1057px) {
  .news-video__flex {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 1057px) {
  .news-video__video {
    flex-basis: 37.5%;
  }
}
@media (max-width: 1056px) {
  .news-video__video {
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    margin-bottom: 1rem;
  }
}
.news-video__video__inner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  border-radius: 8px;
  overflow: hidden;
  z-index: 1;
}
.news-video__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 1057px) {
  .news-video__txt {
    flex-basis: 58%;
  }
}

/* news-table */
.news-table {
  padding-bottom: clamp(3rem, 5vw, 5rem);
}
.news-table__tit {
  text-align: center;
  font-size: calc(1rem + 8 * (100vw - 280px) / 1220);
  font-weight: 600;
  margin-bottom: calc(1.5rem + 0.3em);
}
@media (min-width: 1557px) {
  .news-table__tit {
    font-size: 1.5rem;
  }
}

.wp-block-gallery {
  margin-bottom: 2em;
}

.wp-block-image {
  overflow: hidden;
  border-radius: clamp(8px, 1vw, 1em);
}

.wp-block-file {
  margin-bottom: 2em;
}

.wp-block-columns {
  margin-bottom: clamp(2rem, 4vw, 4rem) !important;
}
.wp-block-columns h2 {
  margin-bottom: 1em !important;
}

/*--------------------------------------------------------------------/
	partner
/--------------------------------------------------------------------*/
/* partner intro */
.partner-intro {
  color: #fff;
  font-size: calc(0.94rem + 4 * (100vw - 280px) / 1220);
  font-weight: 500;
  padding-top: clamp(2rem, 3vw, 3rem);
  margin-bottom: clamp(3rem, 4vw, 4rem);
}
@media (min-width: 1557px) {
  .partner-intro {
    font-size: 1.25rem;
  }
}

/* partner btn */
.partner-btn {
  padding-bottom: clamp(3rem, 6vw, 6rem);
}
@media (min-width: 1057px) {
  .partner-btn {
    margin-bottom: clamp(3rem, 6vw, 6rem);
  }
}
.partner-btn__list {
  position: relative;
  z-index: 1;
}
@media (min-width: 1057px) {
  .partner-btn__list {
    display: flex;
    justify-content: space-between;
    column-gap: 3.84%;
  }
}
@media (min-width: 1557px) {
  .partner-btn__list {
    column-gap: 4%;
  }
}
@media (min-width: 1057px) {
  .partner-btn__list li {
    width: 30.77%;
    flex-shrink: 1;
  }
}
@media (min-width: 1557px) {
  .partner-btn__list li {
    width: 30.66%;
  }
}
@media (max-width: 1056px) {
  .partner-btn__list li:not(:last-of-type) {
    margin-bottom: 0.75rem;
  }
}
.partner-btn__list li a {
  text-decoration: none !important;
  color: #202020;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  padding: 1em;
  background: #fff;
  border: 1px solid #E5E9EC;
  padding: 1rem 5%;
  width: 100%;
  height: 60px;
  border-radius: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.partner-btn__list li a:after {
  content: "\e313";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (min-width: 768px) {
  .partner-btn__list li a {
    height: 80px;
    border-radius: 40px;
  }
}
.partner-btn__list li a::after {
  transition: all 0.3s ease 0s;
  position: absolute;
  color: #2CABED;
  top: 50%;
  right: 1em;
  transform: translateY(-45%);
  font-size: 1.29em;
}
@media (max-width: 1056px) {
  .partner-btn__list li a::after {
    font-size: 1.46em;
  }
}
@media (max-width: 767px) {
  .partner-btn__list li a::after {
    font-size: 1.57em;
  }
}
.partner-btn__list li a:hover {
  background: #1C3AFF;
  color: #fff;
  border-color: #fff;
}
.partner-btn__list li a:hover::after {
  color: #fff;
}
.partner-btn__list li a span {
  font-size: calc(1rem + 6 * (100vw - 280px) / 1220);
}
@media (min-width: 1057px) {
  .partner-btn__list li a span {
    font-size: calc(0.84rem + 3 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1557px) {
  .partner-btn__list li a span {
    font-size: 1.25rem;
  }
}

/* partner featurefeature */
.partner-feature {
  padding-top: clamp(3rem, 5vw, 5rem);
}
@media (min-width: 1057px) {
  .partner-feature__list {
    padding-top: 2rem;
  }
}
@media (max-width: 575px) {
  .partner-feature__list > *:not(:last-of-type) {
    margin-bottom: clamp(2rem, 4vw, 4rem);
  }
}

.partner-feature__box__inner {
  display: flex;
  flex-direction: column-reverse;
}
.partner-feature__box__txt {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10%;
  padding-bottom: 1.5rem;
  border-left: 1px solid #2CABED;
}
.partner-feature__box__tit {
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 1.17em;
}
@media (max-width: 1056px) {
  .partner-feature__box__tit {
    font-size: 1.33em;
  }
}
@media (max-width: 767px) {
  .partner-feature__box__tit {
    font-size: 1.42em;
  }
}
@media (min-width: 1057px) {
  .partner-feature__box__tit {
    font-size: calc(1rem + 4 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1557px) {
  .partner-feature__box__tit {
    font-size: 1.25rem;
  }
}
.partner-feature__box__img {
  margin-bottom: clamp(1.25rem, 2vw, 2rem);
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 575px) {
  .partner-feature__box__img {
    width: 80%;
  }
}
.partner-feature__box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #E5E9EC;
}
.partner-feature__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
}
.partner-feature__box__img__inner > div,
.partner-feature__box__img__inner figure,
.partner-feature__box__img__inner a,
.partner-feature__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* partner-shop */
.partner-shop__flex {
  display: flex;
  position: relative;
}
@media (min-width: 1057px) {
  .partner-shop__flex {
    justify-content: space-between;
  }
}
@media (max-width: 1056px) {
  .partner-shop__flex {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
.partner-shop__left {
  position: relative;
}
@media (min-width: 1057px) {
  .partner-shop__left {
    width: 21.33%;
  }
}
@media (min-width: 1057px) {
  .partner-shop__right {
    width: 69.33%;
  }
}
.partner-shop__area {
  display: flex;
  flex-direction: column;
  row-gap: clamp(2rem, 5vw, 5rem);
}

@media (min-width: 1057px) {
  .partner-shop__link {
    position: sticky;
    top: 100px;
  }
}
.partner-shop__link h3 {
  font-weight: 700;
  font-size: calc(1rem + 6 * (100vw - 280px) / 1220);
  padding-bottom: 0.5em;
  color: #158EEA;
}
@media (min-width: 1057px) {
  .partner-shop__link h3 {
    font-size: 1.25rem;
  }
}
.partner-shop__link__list {
  width: 100%;
}
.partner-shop__link__list .is-shoplist {
  width: 100%;
}

.area-box__inner {
  position: relative;
  z-index: 1;
}
.area-box__tit {
  overflow: hidden;
  font-weight: 500;
  margin-bottom: calc(1rem + 1em);
}
.area-box__tit span {
  display: inline-block;
  padding-right: 1em;
  position: relative;
  font-size: calc(1.14rem + 6 * (100vw - 280px) / 1220);
}
@media (min-width: 1057px) {
  .area-box__tit span {
    font-size: calc(1.25rem + 4 * (100vw - 1000px) / 500);
  }
}
@media (min-width: 1557px) {
  .area-box__tit span {
    font-size: 1.5rem;
  }
}
.area-box__tit span::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 100%;
  width: 100vw;
  height: 1px;
  background: #C9CED2;
}
.area-box__shop__list {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 576px) {
  .area-box__shop__list {
    row-gap: 2rem;
  }
  .area-box__shop__list > * {
    width: 50%;
  }
}
@media (max-width: 575px) {
  .area-box__shop__list {
    flex-direction: column;
    row-gap: 1rem;
  }
}

.shop-box__inner {
  position: relative;
  padding: 0 2em;
}
.shop-box__inner::before {
  position: absolute;
  top: 3%;
  left: 0;
  content: "";
  width: 1px;
  height: 94%;
  background: #E5E9EC;
}
.shop-box h4 {
  font-weight: 500;
  margin-bottom: 0.5em;
  font-size: calc(0.94rem + 2 * (100vw - 280px) / 1220);
}
@media (min-width: 1557px) {
  .shop-box h4 {
    font-size: 1.13rem;
  }
}
.shop-box .txt {
  line-height: 1.4;
}

/* partner form */
.partner-form__intro {
  font-size: calc(0.94rem + 4 * (100vw - 280px) / 1220);
  font-weight: 500;
  margin-bottom: clamp(2rem, 4vw, 3rem);
}
@media (min-width: 1557px) {
  .partner-form__intro {
    font-size: 1.25rem;
  }
}

/*--------------------------------------------------------------------/
	documents
/--------------------------------------------------------------------*/
.documents-intro {
  color: #fff;
  font-size: calc(0.94rem + 4 * (100vw - 280px) / 1220);
  font-weight: 500;
  padding-top: clamp(1rem, 2vw, 2rem);
  margin-bottom: clamp(2rem, 4vw, 3rem);
}
@media (min-width: 1557px) {
  .documents-intro {
    font-size: 1.25rem;
  }
}

.documents-btn__inner {
  border-radius: clamp(16px, 2vw, 2em);
  background: #fff;
  border: 1px solid #2CABED;
  padding: clamp(1.5em, 2.5vw, 2.5rem) 5% clamp(2em, 4vw, 4rem);
}
.documents-btn__tit {
  font-family: 'Gantari', 'Noto Sans JP';
  text-align: center;
  color: #2CABED;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.05em;
  font-size: calc(1.14rem + 12 * (100vw - 280px) / 1220);
  margin-bottom: clamp(1.5rem, 2vw, 2rem);
}
@media (min-width: 1557px) {
  .documents-btn__tit {
    font-size: 2rem;
  }
}
.documents-btn__list {
  display: flex;
  flex-direction: column;
  row-gap: clamp(0.5rem, 1vw, 1rem);
}
.documents-btn__btn {
  line-height: 1.4;
}
.documents-btn__btn a {
  border: 1px solid #2CABED;
  background: #fff;
  border-radius: 36px;
  text-decoration: none !important;
  font-weight: 700;
  transition: all 0.3s ease 0s;
  padding: 1em 2em 1em 1.5em;
  display: block;
  color: #202020;
  position: relative;
  width: 100%;
  font-size: calc(0.9rem + 2 * (100vw - 280px) / 1220);
}
.documents-btn__btn a:after {
  content: "\e313";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (min-width: 1557px) {
  .documents-btn__btn a {
    font-size: 1.13rem;
  }
}
.documents-btn__btn a::after {
  position: absolute;
  right: 0.8em;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.4em;
  color: #2CABED;
  transition: all 0.3s ease 0s;
}
.documents-btn__btn a:hover {
  background: #2CABED;
  color: #fff;
}
.documents-btn__btn a:hover::after {
  color: #fff;
  top: calc(50% + 0.2em);
}
.documents-btn__btn a:hover span::before {
  color: #fff;
}
.documents-btn__btn a span {
  padding-left: 1.5em;
  display: block;
  position: relative;
}
.documents-btn__btn a span::before {
  content: "●";
  position: absolute;
  top: 0.2em;
  left: 0;
  color: #2CABED;
  font-size: 0.8em;
}

.documents-list {
  padding-top: clamp(6rem, 9vw, 9rem);
  display: flex;
  flex-direction: column;
  row-gap: clamp(3rem, 6vw, 6rem);
}

.documents-box__tit {
  padding: 1em 1.25em;
  border: 1px solid #2CABED;
  background: #fff;
  border-radius: clamp(6px, 0.5vw, 10px);
  margin-bottom: clamp(1.5rem, 2.5vw, 2.5rem);
  font-size: calc(1rem + 6 * (100vw - 280px) / 1220);
}
@media (min-width: 1557px) {
  .documents-box__tit {
    font-size: 1.38rem;
  }
}
.documents-box__tit span {
  padding-left: 1.5em;
  display: block;
  position: relative;
}
.documents-box__tit span::before {
  content: "●";
  position: absolute;
  top: 0.2em;
  left: 0;
  color: #2CABED;
  font-size: 0.8em;
}
.documents-box__txt {
  padding: 0 2% clamp(1.5em, 2vw, 2em);
}
.documents-box__txt .txt {
  margin-bottom: 1.5em;
}
.documents-box__list {
  display: flex;
  flex-direction: column;
  row-gap: clamp(0.5em, 1vw, 1rem);
}
.documents-box__btn a {
  padding: 1.25em 1.5em 1.25em 2em;
  background: #2CABED;
  text-decoration: none !important;
  display: block;
  transition: all 0.3s ease 0s;
  color: #fff;
  border-radius: 999px;
  font-weight: 500;
  line-height: 1.4;
  font-size: calc(0.85rem + 5 * (100vw - 280px) / 1220);
}
@media (min-width: 1557px) {
  .documents-box__btn a {
    font-size: 1.25rem;
  }
}
.documents-box__btn a span {
  display: block;
  position: relative;
  padding-left: 1.75em;
}
.documents-box__btn a span:before {
  content: "\e901";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.documents-box__btn a span::before {
  position: absolute;
  top: 0.1em;
  left: 0;
  color: #fff;
  font-size: 1.2em;
}
.documents-box__btn a:hover {
  background: #1C3AFF;
}

/*--------------------------------------------------------------------/
	contact_form
/--------------------------------------------------------------------*/
.contact-intro {
  color: #fff;
  font-size: calc(0.94rem + 4 * (100vw - 280px) / 1220);
  font-weight: 500;
  padding-top: clamp(1rem, 2vw, 2rem);
  margin-bottom: clamp(2rem, 4vw, 3rem);
}
@media (min-width: 1557px) {
  .contact-intro {
    font-size: 1.25rem;
  }
}

.contact-list {
  margin-bottom: clamp(3rem, 5vw, 5rem);
  border: 2px solid #0087CA;
  background: #fff;
  border-radius: clamp(16px, 2vw, 2em);
  padding: 1.5rem 5% 2rem;
}
@media (min-width: 768px) {
  .contact-list {
    padding: 3rem 8% 3.5rem;
  }
}

.contact-privacy {
  border: 1px solid #979DA4;
  border-radius: clamp(8px, 1vw, 1em);
}
@media (min-width: 1057px) {
  .contact-privacy {
    padding: 1.5rem 5%;
  }
}
@media (min-width: 1057px) {
  .contact-privacy__inner {
    overflow-y: scroll;
    max-height: 300px;
  }
  .contact-privacy__inner::-webkit-scrollbar {
    width: 8px;
  }
  .contact-privacy__inner::-webkit-scrollbar-track {
    background: transparent;
  }
  .contact-privacy__inner::-webkit-scrollbar-thumb {
    background: #C9CED2;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px #fff;
  }
}
.contact-privacy__tit {
  font-size: calc(1rem + 4 * (100vw - 280px) / 1220);
  font-weight: 500;
  text-align: center;
}
@media (min-width: 1057px) {
  .contact-privacy__tit {
    padding: 3rem 10%;
  }
}
@media (min-width: 1557px) {
  .contact-privacy__tit {
    font-size: 1.25rem;
  }
}
@media (max-width: 1056px) {
  .contact-privacy__tit {
    padding: 1.5rem 6%;
    cursor: pointer;
    position: relative;
  }
  .contact-privacy__tit:after {
    content: "\e313";
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .contact-privacy__tit::after {
    position: absolute;
    right: 1rem;
    top: calc(50% - 10px);
    transition: all 0.3s ease 0s;
    font-size: 1.5rem;
  }
}
.contact-privacy__tit.active::after {
  transform: rotate(180deg);
}
@media (max-width: 1056px) {
  .contact-privacy__txt {
    border-top: 1px solid #979DA4;
  }
}
@media (min-width: 768px) {
  .contact-privacy .txt {
    font-size: 0.88em;
  }
}
@media (min-width: 768px) and (max-width: 1056px) {
  .contact-privacy .txt {
    font-size: 1em;
  }
}
@media (min-width: 768px) and (max-width: 767px) {
  .contact-privacy .txt {
    font-size: 1.07em;
  }
}
@media (min-width: 1057px) {
  .contact-privacy .txt {
    padding: 0 10% 3rem;
  }
}
@media (max-width: 1056px) {
  .contact-privacy .txt {
    padding: 1rem 6% 3rem;
  }
}

/*-------------------------------------------------
	contact form
-------------------------------------------------*/
/* variablea */
.contact-form__tit {
  color: #158EEA;
  text-align: center;
  font-family: 'Gantari', sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  font-size: calc(1.75rem + 20 * (100vw - 280px) / 1220);
  margin-bottom: calc(1.5rem + 0.3em);
}
@media (min-width: 1557px) {
  .contact-form__tit {
    font-size: 3rem;
  }
}
.contact-form .btn-wrap {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
@media (min-width: 576px) {
  .contact-form .btn-wrap {
    max-width: 320px;
  }
}

.contact-table {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.2;
  position: relative;
}
.contact-table img {
  width: 100%;
}
.contact-table th, .contact-table td {
  text-align: left;
}
@media (min-width: 1057px) {
  .contact-table th, .contact-table td {
    display: table-cell;
    padding: 1rem 0 2rem;
  }
}
@media (max-width: 1056px) {
  .contact-table th, .contact-table td {
    display: block;
  }
}
.contact-table th {
  vertical-align: top;
  font-weight: 600;
}
@media (min-width: 1057px) {
  .contact-table th {
    width: max(30%,280px);
  }
}
.contact-table th .required {
  padding: 0.2em 0.4em;
  line-height: 1;
  margin-left: 0.3em;
  font-size: 0.9em;
}
@media (min-width: 1057px) {
  .contact-table td {
    width: 64%;
    padding-left: 4%;
  }
}
@media (max-width: 1056px) {
  .contact-table td {
    padding: 1.5rem 0 2.5rem;
    padding-top: 1.5rem;
  }
}
.contact-table textarea {
  width: 100%;
}

input,
select {
  width: auto;
}
input.long,
select.long {
  width: 100%;
}
input.middle,
select.middle {
  width: 100%;
}
@media (min-width: 768px) {
  input.middle,
  select.middle {
    width: 70%;
  }
}
input.short,
select.short {
  width: 100%;
}
@media (min-width: 768px) {
  input.short,
  select.short {
    width: 30%;
  }
}
input.mini,
select.mini {
  width: 10em;
}

button[class*=sub-btn],
button[class*=back-btn],
button[class*=chack-btn] {
  font-size: 1rem;
  letter-spacing: 0.1em;
}

button[class*=sub-btn] {
  font-weight: normal;
  display: block;
  text-align: center;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #fff;
  background-color: #2CABED;
  border: 1px solid #2CABED;
  padding: 1.5rem 4%;
  width: 100%;
  border-radius: 36px;
}
button[class*=sub-btn]:hover {
  color: #fff;
  background-color: #1C3AFF;
  border-color: #1C3AFF;
}

button[class*=back-btn] {
  margin-top: 1rem;
  font-weight: normal;
  display: block;
  text-align: center;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #4B4D4E;
  background-color: #E5E9EC;
  border: 1px solid #E5E9EC;
  padding: 1.5rem 4%;
  width: 100%;
  border-radius: 36px;
}
button[class*=back-btn]:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

button[class*=chack-btn] {
  font-weight: normal;
  display: block;
  text-align: center;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #fff;
  background-color: #2CABED;
  border: 1px solid #2CABED;
  padding: 1.5rem 4%;
  width: 100%;
  border-radius: 36px;
}
button[class*=chack-btn]:hover {
  color: #fff;
  background-color: #1C3AFF;
  border-color: #1C3AFF;
}

[type=button],
[type=submit] {
  -webkit-appearance: none;
}

.select-wrap {
  position: relative;
}
.select-wrap:after {
  content: "\e313";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.select-wrap::after {
  position: absolute;
  right: 0.1em;
  top: 50%;
  transform: translateY(-50%);
}
.select-wrap.short {
  width: 100%;
}
@media (min-width: 768px) {
  .select-wrap.short {
    width: 30%;
  }
}

/* radio-btn */
.mwform-radio-field:not(:last-child) {
  margin-right: 1em;
}

.mwform-radio-field-text {
  vertical-align: top;
}

input[type=radio] {
  position: absolute;
  opacity: 0;
}
input[type=radio] + .mwform-radio-field-text:before {
  content: "";
  background: #F7F9FB;
  border-radius: 100%;
  border: 1px solid #979DA4;
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  top: 0;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 0.4s ease;
}
input[type=radio]:checked + .mwform-radio-field-text:before {
  background-color: #2CABED;
  box-shadow: inset 0 0 0 5px #f6f6f6;
}
input[type=radio]:focus + .mwform-radio-field-text:before {
  outline: none;
  border-color: #2CABED;
}
input[type=radio]:disabled + .mwform-radio-field-text:before {
  box-shadow: inset 0 0 0 4px #979DA4;
  border-color: #979DA4;
  background: #F7F9FB;
}
input[type=radio] + .mwform-radio-field-text:empty:before {
  margin-right: 0;
}

.vertical-item:not(:first-of-type) {
  margin-top: 1rem !important;
}

/* chack-btn */
@-moz-keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 10px;
  }
}
@-webkit-keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 10px;
  }
}
@keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 10px;
  }
}
@keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 24px;
  }
}
@-webkit-keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 24px;
  }
}
@-moz-keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 24px;
  }
}
input[type=checkbox] {
  display: none;
}

.check-box .vertical-item:not(:first-of-type) {
  margin-top: 1rem !important;
}
.check-box label {
  position: relative;
  cursor: pointer;
}
.check-box label::before {
  content: "";
  height: 20px;
  width: 20px;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid #979DA4;
  margin-right: 1em;
  position: absolute;
  top: -0.05em;
  left: 0;
  display: block;
  transition: border-color ease 0.2s;
  z-index: 1;
}

.mwform-checkbox-field-text {
  vertical-align: top;
  padding-left: calc(20px + 1rem);
  display: block;
}
.mwform-checkbox-field-text::before, .mwform-checkbox-field-text::after {
  position: absolute;
  height: 0;
  width: 4px;
  background-color: #2CABED;
  display: inline-block;
  transform-origin: left top;
  content: "";
  transition: opacity ease 0.5;
  z-index: 2;
}
.mwform-checkbox-field-text::before {
  top: 17.2px;
  left: 8px;
  transform: rotate(-135deg);
}
.mwform-checkbox-field-text::after {
  top: 10px;
  left: 0.6px;
  transform: rotate(-45deg);
}

input[type=checkbox]:checked + .mwform-checkbox-field-text,
.mwform-checkbox-field-text.checked {
  border-color: #2CABED;
}
input[type=checkbox]:checked + .mwform-checkbox-field-text::after,
.mwform-checkbox-field-text.checked::after {
  height: 10px;
  animation: dothabottomcheck 0.2s ease 0s forwards;
}
input[type=checkbox]:checked + .mwform-checkbox-field-text::before,
.mwform-checkbox-field-text.checked::before {
  height: 24px;
  animation: dothatopcheck 0.4s ease 0s forwards;
  box-shadow: 0 0 0 1px #fff;
}

.mw_wp_form .error {
  margin-top: 0.7rem;
}

/* form-parts
----------------------------------------------------------------*/
select, textarea, input {
  background-color: #fff;
  padding: 1em;
  border: 1px solid #979DA4;
  line-height: 1.5;
  border-radius: 4px;
}
select:focus, textarea:focus, input:focus {
  background-color: #fff;
  box-shadow: rgba(58, 134, 232, 0.25) 0 0 4px 1px;
}

input:-ms-input-placeholder, input:-moz-placeholder, input::-webkit-input-placeholder {
  color: #979DA4;
}

textarea {
  height: 10em;
}

/*--------------------------------------------------------------------/
	aside
/--------------------------------------------------------------------*/
@media (min-width: 1057px) {
  .aside {
    width: 26.66%;
  }
}
.aside-news {
  border-radius: clamp(8px, 1vw, 1em);
  background: #fff;
  border: 1px solid #B0DEF9;
  padding: 2em 5%;
}
@media (min-width: 1057px) {
  .aside-news {
    padding: 1.5em 2em 2em;
  }
}
@media (min-width: 1557px) {
  .aside-news {
    padding: 2em 3em 3em;
  }
}

.widget-blog-aside:not(:last-child) {
  margin-bottom: 2.5rem;
}
.widget-blog-aside__tit {
  padding: 0.3rem 0 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #C9CED2;
  font-weight: 500;
  font-size: 1.05em;
}
@media (max-width: 1056px) {
  .widget-blog-aside__tit {
    font-size: 1.2em;
  }
}
@media (max-width: 767px) {
  .widget-blog-aside__tit {
    font-size: 1.28em;
  }
}
.widget-blog-aside ul li {
  line-height: 1.4;
}
.widget-blog-aside ul li:not(:last-of-type) {
  margin-bottom: 0.5em;
}
.widget-blog-aside ul li a {
  padding-left: 1.5em;
  color: #202020;
  position: relative;
  text-decoration: none !important;
  display: block;
}
.widget-blog-aside ul li a::before {
  position: absolute;
  top: 0.3em;
  left: 0.2em;
  content: "●";
  vertical-align: baseline;
  font-size: 0.7em;
  color: #2CABED;
  text-decoration: none !important;
}
@media (max-width: 1056px) {
  .widget-blog-aside ul li a::before {
    font-size: 0.8em;
  }
}
@media (max-width: 767px) {
  .widget-blog-aside ul li a::before {
    font-size: 0.85em;
  }
}
.widget-blog-aside ul li a:hover {
  text-decoration: underline !important;
}

.tag-cloud-link {
  font-size: 1rem !important;
  color: #202020;
  padding-left: 1.5em;
  margin-right: 0.5rem;
  position: relative;
  text-decoration: none !important;
}
.tag-cloud-link:before {
  content: "\e903";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tag-cloud-link::before {
  position: absolute;
  top: 0.6em;
  left: 0.2rem;
  font-size: 0.82em;
  color: #2CABED;
  text-decoration: none !important;
}
@media (max-width: 1056px) {
  .tag-cloud-link::before {
    font-size: 0.93em;
  }
}
@media (max-width: 767px) {
  .tag-cloud-link::before {
    font-size: 1em;
  }
}
.tag-cloud-link:hover {
  text-decoration: underline !important;
}

/* calendar
------------------------------------*/
#wp-calendar {
  border-collapse: separate;
  width: 100%;
}

.calendar_wrap {
  padding: 0.5rem 0.75rem;
  border: 1px solid #C9CED2;
}

.wp-calendar-table {
  margin-bottom: 0.75rem;
}
.wp-calendar-table caption {
  padding: 0.3rem 2%;
}
.wp-calendar-table thead th {
  text-align: center;
  padding: 6px;
  width: auto;
  color: #979DA4;
  font-weight: 500;
}
.wp-calendar-table td {
  text-align: center;
  background: #f6f6f6;
  line-height: 2;
  border: 2px solid #fff;
  font-size: 0.88em;
}
@media (max-width: 1056px) {
  .wp-calendar-table td {
    font-size: 1em;
  }
}
@media (max-width: 767px) {
  .wp-calendar-table td {
    font-size: 1.07em;
  }
}
.wp-calendar-table td a {
  background-color: #2CABED;
  color: #fff;
  transition: all 0.3s ease 0s;
  display: block;
  text-decoration: none !important;
}
.wp-calendar-table td a:hover {
  background-color: #158EEA;
}
.wp-calendar-table td.pad {
  background: transparent;
}
.wp-calendar-table td#today {
  background: #979DA4;
  color: #fff;
}
.wp-calendar-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 0.3rem;
}
.wp-calendar-nav a {
  display: block;
  color: #202020;
  padding: 0.4rem 1rem;
  background-color: #f6f6f6;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.wp-calendar-nav a:hover {
  background-color: #979DA4;
  color: #fff;
}

/*--------------------------------------------------------------------/
	search-form
/--------------------------------------------------------------------*/
.search-form {
  position: relative;
  width: 100%;
  border: 1px solid #979DA4;
  display: flex;
  justify-content: space-between;
}
.search-form__txt {
  height: 40px;
  padding: 0 10px;
  border-radius: 2px;
  outline: 0;
  background: #fff;
  appearance: none;
  border: none;
  width: calc(100% - 50px);
}
.search-form__btn {
  height: 40px;
  width: 50px;
  padding: 2px;
  background: none;
  font-size: 2em;
  vertical-align: bottom;
  color: #C9CED2;
  background: #fff;
  transition: all 0.3s ease 0s;
  border: none;
}
.search-form__btn i {
  font-size: 1.25rem;
}
.search-form__btn:hover {
  color: #2b74e0;
}