/*--------------------------------------------------------------------/
	concept
/--------------------------------------------------------------------*/
/* concept-intro */
.concept-intro {
	&__wrap {
		padding-top: clamp(4rem, 10vw, 10rem);
	}
	position: relative;
	background: $other_c6;
	color: $white;
	z-index: z-index(module, part04);
	@include lightblue_wave_top;
	padding-bottom: clamp(6rem, 10vw, 10rem);
	@include media-breakpoint-up(lg) {
		min-height: 1020px;
	}
	@include media-breakpoint-down(md) {
		padding-top: 72vw;
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part01);
		@include media-breakpoint-up(lg) {
			padding-top: clamp(3rem, 6vw, 6rem);
			width: 620px;
		}
	}
}

.concept-intro {
	&__tit {
		display: flex;
		justify-content: center;
		margin-bottom: clamp(1.5rem, 3vw, 2.5rem);
		@include media-breakpoint-up(lg) {
			margin-bottom: 2.5rem;
		}
		span {
			display: block;
			@include line-h(1.4);
			@include f-w(500);
			@include f-s_xs(1, 27);
			&:nth-child(1) {
				@include l-sp(0.15em);
				@include media-breakpoint-up(lg) {
					@include f-size(38);
				}
			}
			&:nth-child(2) {
				@include l-sp(0.15em);
				@include f-s_xs(1.34, 40);
				@include media-breakpoint-up(lg) {
					@include f-size(52);
				}
			}
		}
	}
	&__txt {
		.txt {
			& p {
				@include f-w(500);
				@include line-h(2);
				margin-bottom: 1.5rem;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

.concept-intro__add01 {
	position: absolute;
	z-index: z-index(module, part03);
	@include media-breakpoint-up(lg) {
		max-width: 740px;
		width: 740px;
		top: 0;
		left: calc(50% + 208px);
	}
	@include media-breakpoint-down(md) {
		width: 72%;
		top: -9vw;
		left: -5%;
	}
	&__inner {
		@include aspect-img(1, 1);
		border-radius: 50%;
		overflow: hidden;
	}
}

.concept-intro__add02 {
	position: absolute;
	z-index: z-index(module, part02);
	@include media-breakpoint-up(lg) {
		width: 400px;
		left: calc(50% - 64px);
		top: 460px;
	}
	@include media-breakpoint-down(md) {
		width: 44%;
		top: 23vw;
		right: -2%;
	}
	&__inner {
		@include aspect-img(1, 1);
		border-radius: 50%;
		overflow: hidden;
	}
}

// concept-problem
.concept-problem {
	padding: clamp(2rem, 4vw, 4rem) 0 clamp(4rem, 6vw, 6rem);
	position: relative;
	z-index: z-index(module, part03);
	@include white_wave_top;
	&::before {
		position: absolute;
		top: calc(100% - 3px);
		left: calc(50% - 60px);
		content: "";
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 45px 60px 0 60px;
		border-color: $white transparent transparent transparent;
		@include media-breakpoint-up(md) {
			left: calc(50% - 80px);
			border-width: 60px 80px 0 80px;
		}
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		@include media-breakpoint-up(md) {
			column-gap: 4%;
			row-gap: 4vw;
			& > * {
				width: 48%;
			}
		}
		@include media-breakpoint-up(lg) {
			column-gap: 2%;
			row-gap: 2rem;
			& > * {
				width: 23.5%;
			}
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: 1.5rem;
		}
	}
}

.concept-problem__box {
	background: $p-gray;
	@include radius-small;
	padding: 2em 5%;
	@include media-breakpoint-up(md) {
		padding: 2em 2.5em;
	}
	&__tit {
		color: $main_c;
		margin-bottom: 1rem;
		@include f-em(18);
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			@include f-em(15);
		}
		ul {
			text-align: left;
			& li {
				line-height: 1.4;
				padding-left: 1.5rem;
				position: relative;
				&::before {
					content: "●";
					color: $main_c;
					position: absolute;
					left: 0;
					top: 0.2em;
					font-size: 0.8em;
				}
				&:not(:last-child) {
					margin-bottom: 0.75rem;
				}
			}
		}
	}
}

// concept-feature
.concept-feature {
	background: $bg_blue3;
	padding-top: clamp(5rem, 10vw, 10rem);
	padding-bottom: clamp(8rem, 14vw, 14rem);
	&__tit {
		text-align: center;
		margin-bottom: calc(2rem + 1.5em);
		color: $sub_c1;
		span {
			display: block;
			@include f-s_xs(1.14, 10);
			@include media-breakpoint-up(xl) {
				@include f-size(28);
			}
		}
		b {
			display: block;
			@include f-s_xs(1.75, 20);
			@include media-breakpoint-up(xl) {
				@include f-size(48);
			}
		}
	}
	&__list {
		counter-reset: feature-num;
	}
}

.concept-feature__box {
	position: relative;
	&:not(:last-of-type) {
		margin-bottom: clamp(3rem, 8vw, 8rem);
	}
	&.is-box01,
	&.is-box03,
	&.is-box05 {
		@include media-breakpoint-up(lg) {
			.concept-feature__box__tit__inner {
				span {
					padding-right: 1em;
					&::after {
						left: 100%;
					}
				}
			}
		}
	}
	&.is-box02,
	&.is-box04,
	&.is-box06, {
		@include media-breakpoint-up(md) {
			.concept-feature__box__flex {
				flex-direction: row-reverse;
			}
		}
		@include media-breakpoint-up(lg) {
			.concept-feature__box__tit__inner {
				flex-direction: row-reverse;
				span {
					min-width: 50%;
					padding-left: 1em;
					&::after {
						right: 100%;
					}
				}
			}
		}
	}
	&.is-box02 {
		.concept-feature__box__img {
			display: flex;
			@include media-breakpoint-only(sm) {
				flex-wrap: wrap;
				column-gap: 2%;
				& > * {
					width: 49%;
				}
			}
			@include media-breakpoint-only(md) {
				flex-direction: column;
				row-gap: 1rem;
			}
			@include media-breakpoint-up(lg) {
				flex-wrap: wrap;
				column-gap: 4%;
				& > * {
					width: 48%;
				}
			}
			@include media-breakpoint-down(xs) {
				flex-direction: column;
				row-gap: 1rem;
			}
		}
	}
}

.concept-feature__box {
	&__tit {
		overflow: hidden;
		margin-bottom: clamp(1.5rem, 2vw, 2.5rem);
		&__inner {
			display: flex;
			span {
				position: relative;
				display: inline-block;
				@include f-s_xs(1, 16);
				@include f-w(700);
				max-width: 95%;
				@include media-breakpoint-up(xl) {
					@include f-size(32);
				}
				@include media-breakpoint-down(md) {
					padding-right: 1em;
				}
				em {
					font-style: normal;
					position: relative;
					&::before {
						counter-increment: feature-num;
						content: counter(feature-num, decimal-leading-zero);
						@include f-family(font03);
						@include f-w(600);
						display: inline-block;
						color: $sub_c1;
						padding-right: 0.2em;
						@include f-s_xs(2.5, 40);
						vertical-align: middle;
						@include media-breakpoint-up(xl) {
							@include f-size(80);
						}
						@include media-breakpoint-down(sm) {
							padding-right: 0.5em;
						}
					}
					&::after {
						@include media-breakpoint-down(sm) {
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							content: "";
							width: 100vw;
							height: 1px;
							background: $sub_c1;
							left: 100%;
						}
					}
				}
				b {
					@include media-breakpoint-down(sm) {
						display: block;
					}
				}
				&::after {
					@include media-breakpoint-up(md) {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						content: "";
						width: 100vw;
						height: 1px;
						background: $sub_c1;
					}
					@include media-breakpoint-only(md) {
						left: 100%;
					}
				}
			}
		}
	}
}

.concept-feature__box {
	&__flex {
		display: flex;
		@include media-breakpoint-up(md) {
			justify-content: space-between;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: 2rem;
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			width: 48%;
		}
	}
	&__img {
		@include media-breakpoint-up(md) {
			width: 42%;
		}
		&__inner {
			@include media-breakpoint-down(sm) {
				@include aspect-img;
			}
			& div {
				border-radius: 8px;
				overflow: hidden;
			}
		}
	}
}

.concept-feature__box__grid {
	margin-bottom: clamp(2rem, 3vw, 3rem);
	@include media-breakpoint-up(lg) {
		display: grid;
		grid-template-columns: 48% 48%;
		grid-template-rows: auto auto auto 1fr;
		column-gap: 4%;
		row-gap: 2rem;
	}
	@include media-breakpoint-down(md) {
		@include flex-column;
		row-gap: clamp(2rem, 3vw, 3rem);
	}
}

.cost-box {
	@include media-breakpoint-up(lg) {
		&.is-cost01 {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
		}
		&.is-cost02 {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
		}
		&.is-cost03 {
			grid-column: 2 / 3;
			grid-row: 1 / 5;
		}
		&.is-cost04 {
			grid-column: 1 / 2;
			grid-row: 3 / 4;
		}
		&.is-cost05 {
			grid-column: 1 / 2;
			grid-row: 4 / 5;
		}
	}
	&__tit {
		line-height: 1.4;
		padding-left: 1.5rem;
		position: relative;
		margin-bottom: 1rem;
		@include f-s_xs(0.94,3);
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
		&::before {
			content: "●";
			color: $main_c;
			position: absolute;
			left: 0;
			top: 0.2em;
			font-size: 0.8em;
		}
	}
	&__flex {
		display: flex;
		flex-wrap: wrap;
		row-gap: 2rem;
		@include media-breakpoint-up(sm) {
			column-gap: 4%;
			& > * {
				width: 48%;
			}
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column;
		}
	}
}

.cost-box__sub {
	&__inner {
		@include flex-c-reverse;
		row-gap: 1rem;
	}
	&__tit {
		margin-bottom: 0.75em;
		@include f-w(700);
		@include media-breakpoint-down(xs) {
			@include f-em(16);
		}
	}
	.txt {
		@include f-em(15);
		@include media-breakpoint-up(sm) {
			@include f-em(14);
		}
	}
	&__img {
		&__inner {
			div {
				border-radius: 8px;
				overflow: hidden;
			}
		}
	}
}

// concept-recommend
.concept-recommend {
	padding-top: clamp(2rem, 4vw, 4rem);
	position: relative;
	z-index: z-index(module, part03);
	@include white_wave_top;
	&__list {
		@include media-breakpoint-up(lg) {
			padding-top: 2rem;
		}
	}
}

.concept-recommend__box {
	margin-bottom: clamp(2rem, 4vw, 4rem);
	&__inner {
		@include flex-c-reverse;
	}
	&__txt {
		width: 90%;
		@include m-a;
		padding-left: 10%;
		padding-bottom: 1.5rem;
		border-left: 1px solid $main_c;
	}
	&__tit {
		@include f-w(600);
		margin-bottom: 1rem;
		@include f-em(20);
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
	}
	&__img {
		margin-bottom: clamp(1.25rem, 2vw, 2rem);
		width: 96%;
		@include m-a;
		@include media-breakpoint-down(xxs) {
			width: 80%;
		}
		&__inner {
			@include aspect-img(1, 1);
			border-radius: 50%;
			overflow: hidden;
			border: 1px solid $l-gray;
		}
	}
}
