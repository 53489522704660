/*--------------------------------------------------------------------/
	method
/--------------------------------------------------------------------*/
/* method-video */
.method-video {
	padding-top: clamp(2rem, 3vw, 3rem);
	margin-bottom: clamp(5rem, 9vw, 9rem);
	&__list {
		width: 100%;
		@include media-breakpoint-up(md) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}
	&__video {
		width: 100%;
		margin-bottom: 2rem;
		@include media-breakpoint-up(md) {
			width: 48%;
		}
		&__inner {
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
			overflow: hidden;
			@include radius-small;
			z-index: z-index(module, part04);
			border: 1px solid $m-gray;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	&__txt {
		.txt {
			padding: 1.5rem 5%;
			background: $o-white;
			@include radius-small;
		}
	}
}

/* method-flow */
.method-flow {
	overflow: hidden;
	margin-bottom: clamp(5rem, 9vw, 9rem);
	&__tit {
		color: $sub_c1;
		span {
			display: inline-block;
			padding-right: 0.75em;
			position: relative;
			@include f-s_xs(1.25, 12);
			@include f-w(700);
			margin-bottom: calc(1.5rem + 0.5em);
			@include media-breakpoint-up(xl) {
				@include f-size(32);
			}
			&::after {
				content: "";
				width: 100vw;
				height: 2px;
				background: $sub_c1;
				position: absolute;
				top: calc(50% - 1px);
				left: 100%;
			}
		}
	}
	&__list {
		counter-reset: flow-num;
		@include media-breakpoint-up(md) {
			padding-top: clamp(1rem, 2vw, 2rem);
		}
	}
}

.method-flow__box {
	position: relative;
	&:not(:last-of-type) {
		padding-bottom: clamp(2rem, 4vw, 4rem);
		&::before {
			position: absolute;
			top: 20px;
			left: 34px;
			content: "";
			background-size: 2px 10px;
			width: 2px;
			height: 100%;
			background-image: linear-gradient(to bottom, $main_c, $main_c 7px, transparent 7px, transparent 3px);
			@include media-breakpoint-up(md) {
				left: 59px;
				background-size: 3px 15px;
				width: 3px;
				background-image: linear-gradient(to bottom, $main_c, $main_c 10px, transparent 10px, transparent 5px);
			}
		}
	}
	&__inner {
		position: relative;
		padding-left: 90px;
		padding-bottom: 2rem;
		@include media-breakpoint-up(md) {
			padding-left: 150px;
		}
	}
	&__txt {
		padding-top: 20px;
		@include media-breakpoint-up(md) {
			padding-top: 30px;
		}
	}
	&__tit {
		@include f-w(700);
		@include f-s_xs(1, 8);
		margin-bottom: 1rem;
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
	&__num {
		position: absolute;
		top: 0;
		left: 0;
		&__inner {
			background: $main_c;
			border-radius: 50%;
			width: 70px;
			height: 70px;
			color: $white;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			@include media-breakpoint-up(md) {
				width: 120px;
				height: 120px;
			}
			span {
				@include f-family(font02);
				@include f-w(500);
				@include l-sp(0);
				@include line-h(1.1);
				@include f-size(12);
				@include media-breakpoint-up(md) {
					@include f-size(18);
				}
				&::after {
					counter-increment: flow-num;
					content: counter(flow-num, decimal-leading-zero);
					@include f-family(font03);
					@include f-w(600);
					display: block;
					@include f-size(32);
					@include media-breakpoint-up(md) {
						@include f-size(52);
					}
				}
			}
		}
	}
}

/* method-partner */
.method-partner {
	a {
		position: relative;
		@include dec-none;
		color: $white;
		@include radius-small;
		padding: 1.5rem 10% clamp(2rem, 3vw, 3rem);
		display: block;
		@include transition;
		background: url(../img/top/top_partner_bg.jpg) center center/cover no-repeat;
		@include media-breakpoint-up(md) {
			padding: 2rem 10% clamp(2rem, 3vw, 3rem);
		}
		&::before {
			@include radius-small;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba($other_c4, 0.64);
		}
		&:hover {
			color: $main_c;
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part01);
	}
	&__tit {
		@include line-h(1.2);
		margin-bottom: 1rem;
		em {
			@include f-family(font02);
			font-style: normal;
			display: block;
			@include f-s_xs(2, 36);
			@include f-w(700);
			@include media-breakpoint-up(xl) {
				@include f-size(70);
			}
		}
		span {
			@include f-w(700);
			@include f-s_xs(1, 8);
			@include media-breakpoint-up(xl) {
				@include f-size(24);
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			@include f-em(15);
		}
	}
}
