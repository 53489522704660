/*--------------------------------------------------------------------/
	breadcrumb
/--------------------------------------------------------------------*/
.breadcrumb {
  position: absolute;
  left: map-get($contmargin,sp);
  top: 5rem;
  padding-right:map-get($contmargin,sp);
  @include media-breakpoint-up(lg){
    left: map-get($contmargin,pc);
    top: 6rem;
    padding-right: map-get($contmargin,pc);
  }
  &__list {
    @include flex-wrap;
    font-size: 0.9em;
    @include media-breakpoint-up(lg) {
      padding-left: .5em;
      font-size: 0.8em;
    }
    & .item-home {
      font-size: 1.2em;
    }
    li {
      position: relative;
      color: $dl-gray;
      &:not(:last-child) {
        padding-right: 1.5rem;
        @include icon(right_arrow,after);
        &::after {
          position: absolute;
          right:0.3rem;
          top:50%;
          transform: translateY(-50%);
        }
      }
    }
    a {
      color: $gray;
      @include transition;
      vertical-align: top;
      &:first-child {
        @include dec-none;
      }
      &:hover {
        color: $main_c;
      }
    }
  }
}
