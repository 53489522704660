/*--------------------------------------------------------------------/
	cases
/--------------------------------------------------------------------*/
.cases {
	&-intro {
		padding: 2rem 4%;
		background: $p-gray;
		@include f-em(18);
		@include f-w(500);
		@include media-breakpoint-up(lg) {
			padding: 2rem 3rem;
			text-align: center;
		}
	}
}

/* スライダーを使用しない場合の一枚画像　*/
.cases-single {
	&__main {
		position: relative;
		max-width: map-get($container-max-widths, lg); //メインコンテンツサイズ
		height: auto;
		margin-bottom: 2rem;
		&__img {
			@include aspect-img;
		}
		& figcaption {
			position: absolute;
			bottom: 0;
			right: 0.5rem;
			display: inline-block;
			background: $black;
			color: $white;
			line-height: 1.4;
			padding: 0.2rem 0.4rem;
			font-size: 0.8em;
			z-index: 1;
		}
	}
}

/* cases-slider */
.cases-slider {
	position: relative;
	height: auto;
	margin-bottom: 2rem;
	@include media-breakpoint-up(lg) {
		@include flex-between;
		margin-bottom: 3rem;
	}
}

@import "../_plugins/slick"; //slick

/* gallery-top */
.gallery-top {
	position: relative;
	@include media-breakpoint-up(lg) {
		width: 60%;
		max-width: 780px;
	}
	@include media-breakpoint-down(md) {
		margin-bottom: 1.5rem;
	}
	&__inner {
		width: 100%;
		position: relative;
	}
	& .slick-arrow {
		border: 1px solid $m-gray;
		background: rgba($white, 0.8);
		display: flex;
		align-items: center;
		@include transition;
		@include radius(50%);
		&:hover {
			background: rgba($sub_c1, 0.8);
		}
		& i {
			display: flex;
			justify-content: center;
			align-items: center;
			@include f-size(20);
			width: 30px;
			height: 30px;
			@include media-breakpoint-up(lg) {
				width: 40px;
				height: 40px;
			}
			&::before {
				color: $m-gray;
			}
		}
	}
	& .slick-prev {
		&::before {
			display: none;
		}
	}
	& .slick-next {
		&::before {
			display: none;
		}
	}
	.sp-slide {
		position: relative;
		overflow: hidden;
		&__inner {
			@include aspect-img();
			a {
				@include radius-small;
			}
			img {
				@include radius-small;
			}
		}
		& figcaption {
			margin-top: 0.75rem;
			color: $txt_c;
			line-height: 1.4;
			z-index: 1;
		}
	}
}

/* gallery-thumbs */
.gallery-thumbs {
	position: relative;
	@include media-breakpoint-up(lg) {
		width: 30%;
	}
	&__inner {
		width: 100%;
		position: relative;
	}
}

.gallery-thumbs__list {
	@include flex-wrap;
	@include media-breakpoint-up(lg) {
		column-gap: 5%;
		row-gap: 1.6vw;
		& > * {
			width: 30%;
		}
	}
	@include media-breakpoint-up(xl) {
		row-gap: 19px;
	}
	@include media-breakpoint-down(md) {
		column-gap: 2%;
		row-gap: 1.95vw;
		& > * {
			width: 15%;
		}
	}
	& .sp-thumbnail {
		@include transition;
		border: 1px solid $l-gray;
		border-radius: 8px;
		overflow: hidden;
		& img {
			@include transition;
		}
		&:hover {
			img {
				transform: scale(1.1, 1.1);
			}
		}
		&__inner {
			@include aspect-img(1, 1);
		}
	}
}

//cases-main
.cases-main {
	&__img {
		max-width: 800px;
		@include m-a;
		&__inner {
			@include aspect-img();
			img {
				height: 100%;
				@include m-a;
				-o-object-fit: contain;
				object-fit: contain;
				@include radius-small;
			}
		}
		figcaption {
			margin-top: 0.7rem;
		}
	}
}

//cases-beforeafter
.cases-ba {
	padding-top: clamp(3rem, 6vw, 6rem);
	&__inner {
		border: 2px solid $main_c;
		position: relative;
		@include radius-small;
		padding: 3em map-get($contmargin, sp) 2em;
		@include media-breakpoint-up(xl) {
			padding: 4em 8% 3em;
		}
		background: $white;
	}
	&__tit {
		position: absolute;
		bottom: calc(100% - 0.5em);
		@include line-h(1);
		@include f-s_xs(1.25, 24);
		@include media-breakpoint-up(xl) {
			@include f-size(48);
		}
		span {
			@include f-family(font02);
			@include f-w(600);
			padding: 0 0.75em;
			background: $white;
			i {
				color: $main_c;
			}
		}
	}
}

.cases-ba__box {
	&:not(:last-of-type) {
		margin-bottom: clamp(2rem, 3vw, 3rem);
	}
	&__inner {
		display: flex;
		@include media-breakpoint-up(md) {
			justify-content: space-between;
			& > * {
				width: 44%;
			}
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
	}
	&__img {
		&__inner {
			@include aspect-img;
			overflow: hidden;
			@include radius-small;
		}
	}
	&__before {
		position: relative;
		@include media-breakpoint-up(md) {
			@include icon(right_arrow, after);
		}
		@include media-breakpoint-down(sm) {
			@include icon(down_arrow, after);
			margin-bottom: 3.5rem;
		}
		&::after {
			position: absolute;
			color: $main_c;

			@include media-breakpoint-up(md) {
				top: 50%;
				transform: translateY(-50%);
				left: calc(100% + 1vw);
				@include f-em(80);
			}
			@include media-breakpoint-up(xl) {
				left: calc(100% + 1.5rem);
			}
			@include media-breakpoint-down(sm) {
				top: 100%;
				left: 50%;
				transform: translateX(-50%);
				@include f-em(60);
			}
		}
	}
	figcaption {
		margin-top: 0.75rem;
	}
}

// cases-textarea
.cases-textarea {
	margin-top: clamp(2rem, 3vw, 3rem);
	padding: 2em map-get($contmargin, sp);
	background: $o-white;
	@include radius-small;
	@include media-breakpoint-up(xl) {
		padding: 3em 8%;
	}
}

// cases-pdf
.cases-pdf {
	margin-top: 2rem;
	@include media-breakpoint-up(lg) {
		margin-top: 3rem;
	}
	&__list {
		@include media-breakpoint-up(lg) {
			@include flex-wrap;
			column-gap: 2%;
			row-gap: 1rem;
			& > * {
				width: 49%;
			}
		}
		& li {
			&:not(:last-of-type) {
				@include media-breakpoint-down(md) {
					margin-bottom: 1rem;
				}
			}
			a {
				padding: 1rem 4% 1rem 4rem;
				@include transition;
				border: 1px solid $m-gray;
				border-radius: 8px;
				position: relative;
				width: 100%;
				height: 100%;
				display: block;
				color: $txt_c;
				@include dec-none;
				@include icon(pdf);
				&::before {
					position: absolute;
					left: 1.25rem;
					@include f-em(30);
					color: $m-gray;
				}
				&:hover {
					background: $gray;
					border-color: $gray;
					color: $white;
				}
			}
		}
	}
}

// cases-video
.cases-video {
	padding-top: clamp(3rem, 6vw, 6rem);
	&__tit {
		margin-bottom: calc(1.5rem + 0.3em);
		@include f-s_xs(1.14, 8);
		@include f-w(600);
		position: relative;
		@include media-breakpoint-up(xl) {
			@include f-size(28);
		}
		&::before {
			content: "";
			width: 100%;
			height: 2px;
			background: $main_c;
			position: absolute;
			left: 0;
			z-index: z-index(module, part04);
			top: calc(50% - 1px);
		}
		span {
			position: relative;
			display: inline-block;
			background: $white;
			padding-right: 1em;
			z-index: z-index(module, part03);
		}
	}
	&__flex {
		@include media-breakpoint-up(lg) {
			@include flex-between;
		}
	}
	&__video {
		@include media-breakpoint-up(lg) {
			flex-basis: 37.5%;
		}
		@include media-breakpoint-down(md) {
			@include m-a;
			max-width: 480px;
			margin-bottom: 1rem;
		}
		&__inner {
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@include radius-small;
			overflow: hidden;
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			flex-basis: 58%;
		}
	}
}

/* cases-table */
.cases-table {
	padding-top: clamp(3rem, 6vw, 6rem);
	&__tit {
		text-align: center;
		@include f-s_xs(1, 8);
		@include f-w(600);
		margin-bottom: calc(1.5rem + 0.3em);
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
}

.cases-access {
	&__map {
		margin-bottom: 1rem;
		position: relative;
		padding: 0;
		height: 0;
		overflow: hidden;
		background-color: $white;
		@include media-breakpoint-up(lg) {
			padding-bottom: 40%;
		}
		@include media-breakpoint-down(md) {
			padding-bottom: 300px;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
		}
	}
}
